import { Button, Grid, Typography, Divider } from "@mui/material";
import { useState } from "react";
import EastIcon from "@mui/icons-material/East";
import OnboardingNav from "./OnboardingNav";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { welcomeUser } from "../store/onboardingSlice";
import "./style.css";
import { generatePdfUsingHtmltoImage } from "../../utils/globalFunctions";

const bkg =
  //"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeBkg.png";
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeFinal.gif";
const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [alreadyMember, setAlreadyMember] = useState(false);
  const [erroMessage, setErroMessage] = useState(false);

  const onboarding = useSelector(
    (state) => state.onboarding?.onboardings?.data
  );

  const welcome = useSelector((state) => state.onboarding?.welcome);
  const [animationClass, setAnimationClass] = useState("page-enter-welcome");

  useEffect(() => {
    setAnimationClass("page-enter-welcome");
    return () => {};
  }, []);

  const handlePageLeave = () => {
    setAnimationClass("page-exit");
    generatePdfUsingHtmltoImage(
      document.getElementById("welcome"),
      "welcome",
      dispatch
    );
    setTimeout(() => {
      if (welcome?.onboarding?.current_step === 1) {
        navigate("/confirm-details");
      }
      if (welcome?.onboarding?.current_step === 2) {
        navigate("/set-password");
      }
      if (welcome?.onboarding?.current_step === 3) {
        navigate("/plan-subscription");
      }
      if (welcome?.onboarding?.current_step === 4) {
        navigate("/connect-linkedIn");
      }
      if (welcome?.onboarding?.current_step === 5) {
        navigate("/onboarding-complete");
      }
    }, 500);
  };

  useEffect(() => {
    dispatch(welcomeUser(email)).then((res) => {
      if (res.payload?.status === 200) {
        if (res.payload?.data?.onboarding?.completed_at !== null) {
          setAlreadyMember(true);
          setTimeout(() => {
            setAnimationClass("page-exit");
            setTimeout(() => {
              navigate("/dashboard");
            }, 500);
          }, 5000);
        }
      } else {
        setErroMessage(true);
        setTimeout(() => {
          setAnimationClass("page-exit");
          setTimeout(() => {
            navigate("/contact");
          }, 500);
        }, 8000);
      }
    });
  }, []);

  return (
    <Grid
      className="welcome"
      id="welcome"
      sx={{
        //backgroundImage: `url(${bkg})`,
        backgroundImage:
          "linear-gradient(to right, #b03302 0%, #FF3001 20%, #FF3001 80%, #b03302 100%)",
        height: "100vh",
      }}
    >
      <OnboardingNav selected={1} />
      <Grid className={`welcome-content ${animationClass}`}>
        {/* <img src={logo} alt="" /> */}
        <img src="/logo.png" alt="" />

        <Typography
          className="glow-text"
          sx={{
            fontSize: { md: "80px", sm: "80px", xs: "50px" },
          }}
        >
          WELCOME
        </Typography>
        {erroMessage ? (
          <>
            It seems like some issue has occured during onboarding process.
            <br />
            We are redirecting you to our Contact Us form—please let us know the
            issue you are experiencing.
          </>
        ) : alreadyMember ? (
          " You have already onboarded as app user. Redirecting to Dashboard . . ."
        ) : (
          <Button
            disableRipple
            mt={4}
            className="nextBtn"
            onClick={handlePageLeave}
          >
            Next
            <EastIcon className="eastIcon" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
export default Welcome;
