import {
  Button,
  TextField,
  styled,
  Box,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { countriesList } from "./countries";
import { updateOnboardingDetails } from "../store/onboardingSlice";
import { error, success } from "../store/alertSlice";
import { generatePdfUsingHtmltoImage } from "../../utils/globalFunctions";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    color: "#FFF",
  },
  "& .MuiSelect-icon": {
    fill: "#FFF",
  },
  "& .MuiInputLabel-root": {
    color: "#FFFFFF66",
    marginBottom: "20px",
    transform: "translate(12px, 8px) scale(1)",
    "& .MuiInputLabel-asterisk": {
      display: "none",
    },
    "&.MuiInputLabel-shrink": {
      display: "none",
    },
  },
});

const StyledCanvas = styled(SignatureCanvas)({
  border: "1px solid white",
  width: "100%",
  height: "200px",
});

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  organization_website: "",
  reason_to_join: "",
  has_confirmed_sales_nav: false,
  current_position: "",
  payment_plan_uuid: "",
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required("You must type email"),
    first_name: yup.string().required("You must type your name"),
    last_name: yup.string().required("You must type your name"),
    phone_number: yup.string().required("You must type your phone number"),
    has_confirmed_sales_nav: yup.boolean().oneOf([true], "You must check this"),
    payment_plan_uuid: yup.string().required("You must select payment plan"),
  })
  .required();

export const ConfirmDetailsForm = ({ handlePageLeave, handleClickOpen }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const onboardingUser = useSelector(
    (state) => state.onboarding.onboardingUser
  );

  const [selectedTab, setSelectedTab] = useState(
    onboardingUser?.signup_request
      ? onboardingUser?.signup_request?.payment_plan?.uuid
      : null
  );
  const [selectedPlan, setSelectedPlan] = useState(
    onboardingUser?.signup_request
      ? onboardingUser?.signup_request?.payment_plan
      : null
  );

  const [placeholder, setPlaceholder] = useState("");
  const [selectedFlag, setSelectedFlag] = useState(null);
  const sortedCountriesList = countriesList.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleChange = (tabValue) => {
    setSelectedTab(tabValue.uuid);
    setSelectedPlan(tabValue);
    setValue("payment_plan_uuid", tabValue.uuid);
  };
  const dispatch = useDispatch();
  const registeredUser = useSelector((state) => state.register.registeredUser);
  const [digitalSign, setDigitalSign] = useState(null);
  const { handleSubmit, setValue, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const formData = watch();

  const sigCanvas = useRef(null);

  const clearSignature = () => {
    sigCanvas.current.clear();
    setDigitalSign(null);
  };

  useEffect(() => {
    reset({ ...onboardingUser });
    setSelectedPlan(onboardingUser?.signup_request?.payment_plan);
    setSelectedTab(onboardingUser?.signup_request?.payment_plan?.uuid);
    setValue(
      "payment_plan_uuid",
      onboardingUser?.signup_request?.payment_plan?.uuid
    );
  }, [onboardingUser, reset]);

  const saveSignature = () => {
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setDigitalSign(signatureData);
    console.log(signatureData);
  };

  const handleGenerateSignature = (name) => {
    const canvas = sigCanvas.current.getCanvas();
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set font and size (you can adjust these)
    ctx.font = "italic 40px Pacifico, cursive"; // Use your chosen signature font
    ctx.fillStyle = "black";

    // Draw text on canvas
    ctx.fillText(name, 10, 50);

    // Save canvas to Base64 image
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setDigitalSign(signatureData);
    if (name !== "") {
      sigCanvas.current.off();
    } else {
      sigCanvas.current.on();
    }
    console.log(signatureData);
  };

  function onSubmit(data) {
    dispatch(
      updateOnboardingDetails({
        ...data,
        signature_data_uri: digitalSign,
      })
    ).then((resp) => {
      if (resp.payload?.status === 200) {
        dispatch(success(`Details updated!`));
       
        handlePageLeave();
      } else {
        handleClickOpen();
        dispatch(error("Error occured"));
      }
    });
  }

  return (
    <div className="">
      <div className=" ">
        <div className="md:flex md:space-x-8 space-y-8 md:space-y-0 justify-between">
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="confirm-details-field w-full"
                {...field}
                error={!!errors.first_name}
                helperText={errors?.first_name?.message}
                required
                placeholder="First Name"
                size="small"
                FormHelperTextProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            )}
            name="first_name"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <CustomTextField
                className="confirm-details-field w-full"
                {...field}
                error={!!errors.last_name}
                helperText={errors?.last_name?.message}
                required
                placeholder="Last Name"
                size="small"
                FormHelperTextProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            )}
            name="last_name"
            control={control}
          />
        </div>
        <div className="md:flex md:space-x-8 justify-between pt-8 space-y-8 md:space-y-0">
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="confirm-details-field w-full"
                {...field}
                disabled
                error={!!errors.email}
                helperText={errors?.email?.message}
                required
                type="email"
                placeholder="Email"
                size="small"
                FormHelperTextProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            )}
            name="email"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="confirm-details-field w-full"
                disabled
                {...field}
                placeholder="Organization name"
                size="small"
                FormHelperTextProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            )}
            name="organization.title"
            control={control}
          />
        </div>
        <div className="md:flex md:space-x-8 justify-between pt-8 space-y-8 md:space-y-0">
          <Controller
            render={({ field }) => (
              <CustomTextField
                select
                className="confirm-details-field w-full"
                {...field}
                error={!!errors.country}
                helperText={errors?.country?.message}
                required
                label="Country"
                size="small"
                FormHelperTextProps={{
                  style: {
                    color: "black",
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        borderRadius: "15px",
                        Width: "60%",
                        background: "#D51900",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#FFF #AA0000",
                      },
                    },
                  },
                }}
                onChange={(e) => {
                  const selectedCountry = countriesList.find(
                    (country) => country.code === e.target.value
                  );
                  setValue("country", e.target.value);
                  setSelectedFlag(selectedCountry?.img);
                  field.onChange(e);
                }}
              >
                {sortedCountriesList?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.code}
                    sx={{
                      textWrap: "nowrap",
                      color: "#FFF",
                      display: "flex",
                      flexWrap: "nowrap",
                    }}
                  >
                    {item.name.length > 15
                      ? `${item?.name?.substring(0, 12)}...`
                      : item.name}
                    ({item.dial_code})
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
            name="country"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <CustomTextField
                className="confirm-details-field w-full"
                {...field}
                error={!!errors.current_position}
                helperText={errors?.current_position?.message}
                required
                placeholder="Designation"
                size="small"
                FormHelperTextProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            )}
            name="current_position"
            control={control}
          />
        </div>
        <div className="md:flex md:space-x-8 justify-between pt-8 space-y-8 md:space-y-0">
          <Controller
            render={({ field }) => (
              <CustomTextField
                className="confirm-details-field w-full"
                {...field}
                error={!!errors.phone_number}
                helperText={errors?.phone_number?.message}
                required
                placeholder="Phone Number"
                size="small"
                InputProps={{
                  startAdornment: selectedFlag ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        color: "#FFF",
                        marginRight: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={selectedFlag}
                        alt="flag"
                        style={{
                          width: "40px",
                          height: "30px",
                          marginRight: "10px",
                        }}
                      />
                      |
                    </div>
                  ) : null,
                }}
                FormHelperTextProps={{
                  style: {
                    color: "black",
                  },
                }}
              />
            )}
            name="phone_number"
            control={control}
          />
          <div className="w-full"></div>
        </div>

        <div className="confirm-details-field-label pt-8 pb-2">
          Select Plan*
        </div>

        <div className="flex items-center justify-between md:justify-start md:space-y-0 flex-wrap md:space-x-4  ">
          {onboardingUser?.organization?.default_payment_plans?.map(
            (plan, index) => (
              <Button
                disabled={onboardingUser?.signup_request?.payment_plan}
                className={
                  selectedTab === plan.uuid
                    ? "select-plan-btn-contained p-3 px-6 mb-2 md:mt-2"
                    : "select-plan-btn-outlined p-3 px-6 mb-2 md:mt-2"
                }
                onClick={() => handleChange(plan)}
              >
                <span className="select-plan-btn-text">{plan.name}</span>
              </Button>
            )
          )}
          {selectedPlan?.custom === true && (
            <Button
              disabled={onboardingUser?.signup_request?.payment_plan}
              className={"select-plan-btn-contained p-3 px-6 mb-2 md:mt-2"}
            >
              <span className="select-plan-btn-text-reg">
                {selectedPlan.name}
              </span>
            </Button>
          )}
        </div>
        {selectedPlan && (
          <div
            className="select-plan-btn-text-reg-det pb-2"
            style={{ color: "white" }}
          >
            ${selectedPlan.price + "/" + selectedPlan.tier}
          </div>
        )}
        {!formData.payment_plan_uuid && (
          <div
            className="select-plan-btn-text-reg-det pb-2"
            style={{ color: "black" }}
          >
            Please select plan
          </div>
        )}
        <div className="confirm-details-field-label pt-8 pb-2">
          Digital Signature*
        </div>

        <CustomTextField
          className="confirm-details-field w-full lg:w-1/2 mb-4"
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            handleGenerateSignature(e.target.value);
          }}
          placeholder="Type your name or sign below"
          size="small"
        />

        <br />
        {/* <Button onClick={handleGenerateSignature}>Generate Signature</Button> */}
        <div className="md:flex md:space-x-8 justify-between space-y-8 md:space-y-0">
          <Box className="confirm-details-field ">
            <StyledCanvas
              disabled
              ref={sigCanvas}
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            />
            <div className="p-4">
              <Button
                variant="contained"
                color="primary"
                onClick={clearSignature}
                style={{
                  backgroundColor: "transparent",
                  textTransform: "none",
                  border: "1px solid white",
                }}
              >
                Clear Signature
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={saveSignature}
                style={{
                  marginLeft: "10px",
                  backgroundColor: "red",
                  textTransform: "none",
                }}
              >
                Save Signature
              </Button>
            </div>
          </Box>
        </div>
        <div className="confirm-details-field-label py-2">
          By signing digitally, I confirm that I have read and agree with the
          FacoonLabs Subscription Agreement.
        </div>

        <Controller
          name="has_confirmed_sales_nav"
          control={control}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <FormControl error={!!errors.has_confirmed_sales_nav} required>
              <FormControlLabel
                label={
                  <>I confirm I have a LinkedIn Sales Navigator Account </>
                }
                control={
                  <Checkbox
                    size="large"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "green",
                      },
                    }}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(ev) => onChange(ev.target.checked)}
                    inputRef={ref}
                    required
                  />
                }
              />
              <FormHelperText style={{ color: "black" }}>
                {errors?.has_confirmed_sales_nav?.message}
              </FormHelperText>
            </FormControl>
          )}
        />

        <div className="pt-8">
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "white",
              color: "#B50000",
              borderRadius: "30px",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            <span className="sign-button">Looking Good</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
