import { Grid, Typography, useMediaQuery } from "@mui/material";
import { PricingCard } from "./PricingCard";
import { Partners } from "../homepage/Partners";
import { BeginYourJourney } from "../homepage/BeginYourJourney";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDefaultPaymentPlans } from "../../store/homeSlice";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import theme from "../../../Theme/theme.jsx";

export const Pricing = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfile.userProfile);
  const paymentPlans = useSelector((state) => state.home.paymentPlans);
  const monthlyPlan = paymentPlans?.find((plan) => plan.name === "Monthly");
  const semiAnnualPlan = paymentPlans?.find(
    (plan) => plan.name === "Semi Annual"
  );
  const annualPlan = paymentPlans?.find((plan) => plan.name === "Annual");
  const isCustomRange = useMediaQuery("(max-width:385px)");

  useEffect(() => {
    dispatch(getDefaultPaymentPlans());
  }, []);

  
  return (
    <>
      <Navbar />
      <div className="flex flex-col lg:flex md:pt-24 sm:pt-10 xs:pt-8" 
        style={{
          backgroundImage:
            "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/pricingBg.png)",
          backgroundSize: "contain",
          backgroundPosition: "top center",
        }}
      >
        <div className=" h-full py-16 sm:px-4">
          {/*<div className="pricing-title"> Pricing Plans</div>
           <Typography
            className="pricing-heading"
            sx={{ fontSize: { md: "70px", sm: "60px", xs: "35px" } }}
          >
            {" "}
            Choose the Plan That
          </Typography>
          <Typography
            className="pricing-heading"
            sx={{ fontSize: { md: "70px", sm: "60px", xs: "35px" } }}
          >
            {" "}
            Fits Your Needs
          </Typography> */}
          <div class="px-16 py-16 mt-16 mb-16 min-[320px]:px-2">
            {/* <Grid
              container
              spacing={{ md: 9, sm: 9, xs: 0 }}
              style={{
                backgroundImage:
                  "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/pricingBg.png)",
                backgroundSize: "contain",
              }}
            >
              <Grid item lg={4} md={6} sm={12} xs={12}>
                {monthlyPlan && (
                  <PricingCard
                    title={monthlyPlan?.name}
                    charges={monthlyPlan?.discounted_price}
                    originalPrice={monthlyPlan?.price}
                    duration={"month"}
                    btnTitle={"Choose Monthly Plan"}
                    features={monthlyPlan?.benefits}
                  />
                )}
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}
                xs={12}
                my={{ md: 0, sm: 4, xs: 12 }}
              >
                {semiAnnualPlan && (
                  <PricingCard
                    bestDeal
                    title={semiAnnualPlan?.name}
                    charges={semiAnnualPlan?.discounted_price}
                    originalPrice={semiAnnualPlan?.price}
                    duration={"6 months"}
                    btnTitle={"Start 6-Months Journey"}
                    features={semiAnnualPlan?.benefits}
                  />
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                {annualPlan && (
                  <PricingCard
                    title={annualPlan?.name}
                    charges={annualPlan?.discounted_price}
                    originalPrice={annualPlan?.price}
                    duration={"year"}
                    btnTitle={"Unlock Annual Benefits"}
                    features={annualPlan?.benefits}
                  />
                )}
              </Grid>
            </Grid> */}

            <Grid
              container
              spacing={{ md: 9, sm: 9, xs: 0 }}
              sx={{
                backgroundImage:
                  "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/pricingBg.png)",
                backgroundSize: "contain",
                backgroundPosition: "top center",
                //backgroundRepeat:"no-repeat",
                display:"flex",
                justifyContent:"center",
                padding:"50px"

              }}
            >
            <Grid className="pricing-card-best-deal-wrapper lg:-mt-14"
              sx={{
                backgroundImage:
                  "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bestDealBg.gif)",
                backgroundSize: "cover",
                padding: "60px",
                width:"75%",
                opacity:"76%"
              }}>
              <Typography sx={{ fontSize: "25px", fontWeight: "800", textWrap: "wrap",textAlign:"center" }}>
                We offer customized prices based on your needs.<br /> Contact us for discussing beta price.
              </Typography>
            </Grid>

            </Grid>

            <div
                  className="sign-button flex w-full justify-center mt-8 mb-4"
                  style={{ color: "#FFF", fontSize: "40px" }}
                >
                  Book a demo with us
                </div>
                <Grid
                  mb={2}
                  sx={{
                    backgroundImage:
                      "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/pricingBg.png)",
                    backgroundSize: "100% 100%",
                    backgroundPosition: "bottom right",
                    transform: {
                      md: "scale(1)",
                      sm: "scale(1)",
                      xs: "scale(0.82)",
                    },
                    position: {
                      md: "block",
                      sm: "block",
                      xs: isCustomRange ? "relative" : "block",
                    },
                    right: "30px",
                    width: "100%",

                    [theme.breakpoints.down(425)]: {
                      width: "70vh",
                    },
                    [theme.breakpoints.down(360)]: {
                      width: "60vh",
                    },
                  }}
                >
                  <InlineWidget
                    url="https://calendly.com/ben-facoonlabs/30min"
                  //  prefill={prefill}
                    sx={{ width: "100%" }}
                  />
                </Grid>
                {/* {formData.signup_discovery_call_meeting_url === "" && (
                  <div
                    className="select-plan-btn-text-reg-det pb-2"
                    style={{ color: "red" }}
                  >
                    Please book a demo with us to proceed.
                  </div>
                )} */}


          </div>
          <Partners />
          <div className="my-28">
            <BeginYourJourney />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
