import { Grid, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const info ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/info.png";
const infoDark ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/infoBlack.png";
const settings ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/settingsI.png";
const settingsDark ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/settingsIBlack2.png";
const dataSharing ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/dataSharing.png"
const dataSharingDark = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/dataSharingBlack.png"
const security = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/security.png";
const securityDark = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/securityBlack.png	";
const rights ="	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/rights.png";
const rightsDark ="	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/rightsBlack.png";
const changePolicy ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/changePolicy.png";
const changePolicyDark ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/changePolicyBlack.png";

export const Policy = () => {
    const navigate = useNavigate();
    const themeMode = useSelector((state) => state.themeMode.mode);
    return (
        <Grid
            sx={{
                height: "fit-content",
                padding: { md: "100px 150px", sm: "130px 100px", xs: "150px 30px" },
                color: themeMode === "dark" ? "#FFF" : "#000",
                position: "relative",
            }}
        >
            <Button
                disableRipple
                onClick={() => navigate("/dashboard/help-center")}
                sx={{
                    position: "absolute",
                    left: { md: "30px", sm: "30px", xs: "0px" },
                    top: { md: "100px", sm: "70px", xs: "80px" },
                    color: themeMode === "dark" ? "#FFF" : "#000",
                    textTransform: "capitalize",
                    "&:hover": { background: "transparent" },
                }}
            >
                <span className="pr-2 cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 38 38"
                        style={{
                            background: themeMode === "dark" ? "#52000099" : "transparent",
                            borderRadius: "50%",
                            padding: "7px",
                        }}
                    >
                        <path
                            d="M18.5055 37.0199C16.0744 37.0239 13.6665 36.5478 11.4199 35.6188C9.17334 34.6899 7.13236 33.3263 5.41413 31.6065C-1.80471 24.3877 -1.80471 12.6426 5.41413 5.42376C8.91099 1.9269 13.5601 0 18.5055 0C23.4509 0 28.1 1.92542 31.5969 5.42376C38.8157 12.6426 38.8157 24.3877 31.5969 31.6065C29.8786 33.3263 27.8377 34.6899 25.5911 35.6188C23.3445 36.5478 20.9366 37.0239 18.5055 37.0199ZM7.50839 29.5122C13.572 35.5758 23.439 35.5758 29.5026 29.5122C32.4396 26.5737 34.057 22.6681 34.057 18.5151C34.057 14.3621 32.4396 10.4565 29.5026 7.51802C26.5656 4.57954 22.66 2.96218 18.5055 2.96218C14.351 2.96218 10.4469 4.58102 7.50839 7.51802C4.56991 10.455 2.95404 14.3607 2.95404 18.5151C2.95404 22.6696 4.57139 26.5737 7.50839 29.5122Z"
                            fill={themeMode === "dark" ? "white" : "black"}
                        />
                        <path
                            d="M21.9091 26.8027C21.7146 26.8029 21.5219 26.7647 21.3422 26.6902C21.1625 26.6157 20.9993 26.5065 20.862 26.3688L14.0549 19.5617C13.9172 19.4242 13.808 19.261 13.7335 19.0813C13.659 18.9017 13.6206 18.709 13.6206 18.5145C13.6206 18.32 13.659 18.1274 13.7335 17.9477C13.808 17.768 13.9172 17.6048 14.0549 17.4674L20.862 10.6603C20.9995 10.5228 21.1627 10.4137 21.3424 10.3393C21.5221 10.2649 21.7146 10.2266 21.9091 10.2266C22.1036 10.2266 22.2961 10.2649 22.4758 10.3393C22.6555 10.4137 22.8187 10.5228 22.9562 10.6603C23.0937 10.7978 23.2028 10.9611 23.2772 11.1407C23.3517 11.3204 23.39 11.513 23.39 11.7074C23.39 11.9019 23.3517 12.0945 23.2772 12.2741C23.2028 12.4538 23.0937 12.6171 22.9562 12.7546L17.1963 18.5145L22.9562 24.2745C23.164 24.4813 23.3057 24.7453 23.3632 25.0328C23.4207 25.3203 23.3916 25.6184 23.2794 25.8893C23.1672 26.1602 22.977 26.3916 22.733 26.5543C22.4891 26.7169 22.2023 26.8034 21.9091 26.8027Z"
                            fill={themeMode === "dark" ? "white" : "black"}
                        />
                    </svg>
                </span>
                Back To Help Center
            </Button>

            <Typography
                mt={{ md: 3, sm: 3 }}
                sx={{
                    fontSize: { md: "40px", sm: "40px", xs: "35px" },
                    fontWeight: "600",
                    textAlign: "center",
                }}
            >
                Privacy Policy
            </Typography>

        
            <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                At FacoonLabs, we are committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, and protect your information when you visit or use our website and services.
            </Typography>

            <Typography
                my={2}
                sx={{
                    fontSize: { md: "24px", sm: "24px", xs: "18px" },
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={themeMode === "dark" ? info : infoDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                />
                Information We Collect:
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                We may collect personal information such as:
                <ul style={{ marginLeft: "20px" }}>
                    <li>Name, email address, phone number, and company details</li>
                    <li>Browsing information (cookies, IP address)</li>
                </ul>
            </Typography>
            <Typography
                my={2}
                sx={{
                    fontSize: { md: "24px", sm: "24px", xs: "18px" },
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={themeMode === "dark" ? settings : settingsDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                />
                How We Use Your Information:
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                Your information is used to:
                <ul style={{ marginLeft: "20px" }}>
                    <li>Improve our services</li>
                    <li>Personalize your experience</li>
                    <li>Send promotional material and updates</li>
                    <li>Provide customer support</li>
                </ul>
            </Typography>




            <Typography
                my={2}
                sx={{
                    fontSize: { md: "24px", sm: "24px", xs: "18px" },
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={themeMode === "dark" ? dataSharing : dataSharingDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                />
                Data Sharing:
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                We do not sell your personal information to third parties. However, we may share information with trusted service providers to enhance your experience.
            </Typography>




            <Typography
                my={2}
                sx={{
                    fontSize: { md: "24px", sm: "24px", xs: "18px" },
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={themeMode === "dark" ? security : securityDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                />
                Security:
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                We implement appropriate security measures to protect your data from unauthorized access or disclosure.
            </Typography>
           



            <Typography
                my={2}
                sx={{
                    fontSize: { md: "24px", sm: "24px", xs: "18px" },
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={themeMode === "dark" ? rights : rightsDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                />
                Your Rights:
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                You can request access, correction, or deletion of your personal information by contacting us.
            </Typography>


            <Typography
                my={2}
                sx={{
                    fontSize: { md: "24px", sm: "24px", xs: "18px" },
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={themeMode === "dark" ? changePolicy : changePolicyDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                />
                Changes to this Policy:
            </Typography>
            <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                We may update this policy periodically. Please review it regularly to stay informed.
            </Typography>


            <Typography my={3} sx={{ fontSize: "13px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Contact Us:</span> For any questions or concerns regarding this privacy policy, contact us at 
                    <span style={{ fontWeight: "700" }}>
                        <Link to="mailto:support@facoonlabs.com">{" "}support@facoonlabs.com</Link>
                    </span>
            </Typography>

        </Grid>
    );
};
