const Anab = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/anab.jpeg"
const Saad = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/saad.jpg"
const Humna = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/IMG20240428125011.jpg"
const Ayesh = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ayesh.jpg"
const Arslan = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/image.png"
const Nabeel = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/nabeel.png"
const Nimra = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/nimra.jpg"
const Hamza = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/889d0947-76ef-4945-8dc1-13a6d08522d4.jpg"
const Rameez = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/117537463_2756788811206319_5390222129646328307_n.jpg"
const Fawad = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/fawad.jpg"



const team=[
    {
        id:1,
        name:"Jeremy Metzker",
        designation:" Founder & CEO",
        img:""
    },
    {
        id:2,
        name:"Syed Anab Imam",
        designation: "Co founder, Product Owner & CTO",
        img:Anab
    },
    {
        id:3,
        name:"Humna Khalid",
        designation: "Product Manager",
        img:Humna
    },
    {
        id: 4,
        name: "Nabeel Ahmed",
        designation: "Lead Designer",
        img: Nabeel
    },
    {
        id: 5,
        name: "Nimra Khalid",
        designation: "Lead Developer",
        img: Nimra
    },
    {
        id: 6,
        name: "Arslan Ahmad",
        designation: "Lead Developer",
        img: Arslan
    },
    {
        id: 7,
        name: "Muhammad Saad Haider",
        designation: "Lead Developer",
        img: Saad
    },
    {
        id: 8,
        name: "Rameez Arif",
        designation: "Creative Specialist",
        img:Rameez
    },
    {
        id: 9,
        name: "Fawad Hussain",
        designation: "Designer",
        img:Fawad,
    },
    {
        id: 10,
        name: "Syed Muhammad Hamza",
        designation: "Developer",
        img: Hamza
    },
    {
        id: 11,
        name: "Ayesh Bhatti",
        designation: "Developer",
        img: Ayesh
    },
]
export default team;