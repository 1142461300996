import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  LinearProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OnboardingNav from "./OnboardingNav";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  accountConnection,
  finishOnboarding,
  setOnboardingShowCaptcha,
  solveCodeCheckpoint,
} from "../store/onboardingSlice";
import { error, success } from "../store/alertSlice";
import {
  connectLinkedInAccount,
  disconnectLinkedInAccount,
  getLinkedInConnectedStatus,
} from "../store/settingsSlice";
import { ArkoseCaptchaIntegration } from "./captcha-integration/ArkoseCaptchaIntegration";
import OTPDialog from "./OTPDialog";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OnboardingErrorDialog from "./OnboardingErrorDialog";
import { LoaderProgress } from "../dashboard/LoaderProgress";
import AppValidationDialog from "./AppValidationDialog";
import { generatePdfUsingHtmltoImage } from "../../utils/globalFunctions";

const bkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeFinal.gif";

const ConnectLinkedIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showCaptcha = useSelector((state) => state.onboarding.showCaptcha);
  const connectLinkedInLoading = useSelector(
    (state) => state.settings.connectLinkedInLoading
  );

  const codeLoading = useSelector((state) => state.onboarding.codeLoading);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const [openOTP, setOpenOTP] = useState(false);

  const handleClickOpenOTP = () => {
    setOpenOTP(true);
  };

  const handleCloseOTP = () => {
    setOpenOTP(false);
  };

  const [openValidation, setOpenValidation] = useState(false);

  const handleClickOpenValidation = () => {
    setOpenValidation(true);
  };

  const handleCloseValidation = () => {
    setOpenValidation(false);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    dispatch(disconnectLinkedInAccount());
    dispatch(connectLinkedInAccount(data)).then((res) => {
      if (res.payload?.status === 200) {
        dispatch(success(res.payload.data.message));
        navigate("/onboarding-complete");
      } else if (res.payload?.status === 201) {
        dispatch(success(res.payload.data.message));
        navigate("/onboarding-complete");
      } else {
        if (res.payload?.status === 202) {
          dispatch(success(res.payload.data.message));

          if (res.payload.data?.checkpoint_details?.type === "CAPTCHA") {
            dispatch(setOnboardingShowCaptcha(true));
            const publicKey = res.payload.data?.checkpoint_details?.public_key;
            const data = res.payload.data?.checkpoint_details?.data;

            // 1. Instanciate the captcha frame and logic
            const captcha = new ArkoseCaptchaIntegration(publicKey, data);

            captcha.onLoaded = () => {
              console.log(
                "Captcha has been successfully loaded into the DOM !"
              );
            };

            captcha.onSuccess = (token) => {
              console.log("Captcha has been resolved !", { token });
              dispatch(
                solveCodeCheckpoint({
                  code: token,
                })
              ).then((res) => {
                if (res.payload?.status === 201) {
                  dispatch(success(res.payload.data.message));
                  dispatch(getLinkedInConnectedStatus());
                  navigate("/onboarding-complete");
                }
                if (res.payload?.status === 200) {
                  dispatch(success(res.payload.data.message));
                  dispatch(getLinkedInConnectedStatus());
                  navigate("/onboarding-complete");
                }
                if (res.payload?.status === 202) {
                  if (res.payload.data?.checkpoint_details?.type === "OTP") {
                    dispatch(success("Please check your Email for OTP"));
                    handleClickOpenOTP();
                  }
                  if (
                    res.payload.data?.checkpoint_details?.type === "CAPTCHA"
                  ) {
                    const publicKey =
                      res.payload.data?.checkpoint_details?.public_key;
                    const data = res.payload.data?.checkpoint_details?.data;

                    // 1. Instanciate the captcha frame and logic
                    const captcha = new ArkoseCaptchaIntegration(
                      publicKey,
                      data
                    );

                    captcha.onLoaded = () => {
                      console.log(
                        "Captcha has been successfully loaded into the DOM !"
                      );
                    };
                    captcha.onSuccess = (token) => {
                      console.log("Captcha has been resolved !", { token });
                      dispatch(
                        solveCodeCheckpoint({
                          code: token,
                        })
                      ).then((res) => {
                        if (res.payload?.status === 202) {
                          if (
                            res.payload.data?.checkpoint_details?.type === "OTP"
                          ) {
                            dispatch(
                              success("Please check your Email for OTP")
                            );
                            handleClickOpenOTP();
                          }
                          if (
                            res.payload.data?.checkpoint_details?.type ===
                            "IN_APP_VALIDATION"
                          ) {
                            handleClickOpenValidation();
                          }
                          if (
                            res.payload.data?.checkpoint_details?.type ===
                            "CAPTCHA"
                          ) {
                            const publicKey =
                              res.payload.data?.checkpoint_details?.public_key;
                            const data =
                              res.payload.data?.checkpoint_details?.data;

                            // 1. Instanciate the captcha frame and logic
                            const captcha = new ArkoseCaptchaIntegration(
                              publicKey,
                              data
                            );

                            captcha.onLoaded = () => {
                              console.log(
                                "Captcha has been successfully loaded into the DOM !"
                              );
                            };
                          }
                        } else {
                          console.log("OTP Flow aborted!");
                        }
                      });
                      captcha.unload("captcha-frame");
                    };
                    captcha.onError = () => {
                      console.log("Captcha resolution failed !");
                      handleClickOpen();
                    };

                    // 3. Load the captcha
                    captcha.load("captcha-frame");
                  }
                } else {
                  console.log("OTP Flow aborted!");
                }
              });
              captcha.unload("captcha-frame");
            };

            captcha.onError = () => {
              console.log("Captcha resolution failed !");
              handleClickOpen();
            };

            // 3. Load the captcha
            captcha.load("captcha-frame");
          }

          if (
            res.payload.data?.checkpoint_details?.type === "IN_APP_VALIDATION"
          ) {
            handleClickOpenValidation();
          } else {
            dispatch(success("OTP Flow"));
          }
        } else {
          handleClickOpen();
        }
      }
    });
  };

  const [animationClass, setAnimationClass] = useState("page-enter");

  useEffect(() => {
    setAnimationClass("page-enter");
    return () => {};
  }, []);

  const handlePageLeave = () => {
    generatePdfUsingHtmltoImage(
      document.getElementById("connect_linkedin"),
      "connect_linkedin",
      dispatch
    );
    setAnimationClass("page-exit");
    setTimeout(() => {
      navigate("/onboarding-complete");
    }, 500);
  };

  return (
    <Grid
      className="welcome"
      id="connect_linkedin"
      sx={{
        backgroundImage:
          "linear-gradient(to right, #b03302 0%, #FF3001 20%, #FF3001 80%, #b03302 100%)",
        minHeight: {
          md: "100vh !important",
          sm: "120vh !important",
          xs: "110vh !important",
        },
        maxHeight: {
          md: "180vh !important",
          sm: "180vh !important",
          xs: "180vh !important",
        },
      }}
    >
      <OnboardingNav selected={6} />

      <Grid
        className={animationClass}
        //mt={8}
        mt={{ md: 18, sm: 18, xs: 18 }}
        mb={{ md: "", sm: 8, xs: 10 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: { md: "flex-start", sm: "center", xs: "center" },
          width: { md: "60%", sm: "60%", xs: "90%" },
        }}
      >
        {connectLinkedInLoading && <LoaderProgress />}

        {showCaptcha && (
          <>
            <h1 className={`text-center mt-10 pt-6 pb-6  text-light`}>
              Please solve captcha
            </h1>
          </>
        )}
        <div
          id="captcha-frame"
          className="flex justify-center w-full mt-10"
        ></div>

        {codeLoading && (
          <>
            <div className="flex flex-col justify-center items-center my-2">
              Loading...
            </div>
            <LinearProgress />
          </>
        )}

        <Typography
          //mt={4}
          sx={{
            fontSize: { md: "40px", sm: "40px", xs: "30px" },
            fontWeight: "700",
            display: "flex",
            alignItems: "flex-start",
            top: "10%",
            textShadow:
              "0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.5)",
          }}
        >
          Linked
          <LinkedInIcon
            sx={{
              fontSize: { md: "50px", sm: "50px", xs: "40px" },
              filter:
                "drop-shadow(0 0 10px rgba(255, 255, 255, 0.6)) drop-shadow(0 0 20px rgba(255, 255, 255, 0.5))",
            }}
          />
        </Typography>

        <Typography
          mt={4}
          sx={{
            fontSize: { md: "40px", sm: "40px", xs: "30px" },
            fontWeight: "700",
          }}
        >
          Sign in
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "18px", sm: "18px", xs: "14px" },
            fontWeight: "400",
          }}
        >
          Stay updated on your professional world.
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: {
              xs: "90%",
              sm: "75%",
              md: "60%",
              lg: "50%",
              xl: "43%",
            },
            margin: "20px 0px",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Email Address*"
                  type="email"
                  // error={!!errors.password}
                  // helperText={errors?.password?.message}
                  required
                  InputProps={{
                    style: {
                      color: "#FFF",
                      borderRadius: "15px",
                      backgroundColor: "transparent",
                      border: "1px solid #FFFFFF",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#FFF",
                    },
                    shrink: true,
                  }}
                  sx={{
                    marginY: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Password*"
                  type={showPassword ? "text" : "password"}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          style={{ color: "#FFF" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      color: "#FFF",
                      borderRadius: "15px",
                      backgroundColor: "transparent",
                      border: "1px solid #FFFFFF",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#FFF",
                    },
                    shrink: true,
                  }}
                  sx={{
                    marginY: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Box>
        <div className="flex space-x-2 items-center">
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "capitalize",
              padding: "8px 20px",
              border: "1px solid transparent",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            Connect
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handlePageLeave}
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "transparent",
              borderRadius: "30px",
              color: "#FFF",
              textTransform: "capitalize",
              padding: "8px 20px",
              border: "1px solid #FFF",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            Skip
          </Button>
        </div>
      </Grid>
      {/* </div> */}
      <OnboardingErrorDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
      <OTPDialog
        open={openOTP}
        handleClose={handleCloseOTP}
        handleClickOpen={handleClickOpenOTP}
      />
      <AppValidationDialog
        open={openValidation}
        handleClose={handleCloseValidation}
        handleClickOpen={handleClickOpenValidation}
      />
    </Grid>
  );
};
export default ConnectLinkedIn;
