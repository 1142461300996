import { Grid } from "@mui/material";
export const OurTeamCard = ({ name, designation, bgImage,img }) => {

  return (
    <div
      className="our-team-card h-full"
      style={{
        maxWidth: "290px",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col justify-end h-full p-6" 
        style={{
          borderRadius:"15px",
          background: "linear-gradient(0deg, #000 0%, transparent 100%)"
        }}>
        <div className="team-member-name ">{name}</div>
        <div className="team-member-designation ">{designation}</div>
      </div>
    </div>
  );
};
