import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: { messages: [], bannerMessage: null, bannerUrl: null }, 
  reducers: {
    success: (state, action) => {
      state.messages.push({
        type: "success",
        title: "Successful",
        message: action.payload,
      });
    },
    error: (state, action) => {
      state.messages.push({
        type: "error",
        title: "Failed",
        message: action.payload,
      });
    },
    info: (state, action) => {
      state.messages.push({
        type: "info",
        title: "Info",
        message: action.payload,
      });
    },
    warning: (state, action) => {
      state.messages.push({
        type: "warning",
        title: "Warning",
        message: action.payload,
      });
    },
    removeAlert: (state) => {
      state.messages.shift(); 
    },
    clear: (state) => {
      state.messages = []; 
    },
    banner: (state, action) => {
      state.type = 'banner';
      state.title = 'Banner';
      state.bannerMessage = action.payload?.bannerMessage;
      state.bannerUrl = action.payload?.bannerUrl;
    },
    removebanner: (state) => {
      state.bannerMessage = null; 
      state.bannerUrl = null; 
    },
  },
});

export const { success, error, info, warning, removeAlert, clear, banner, removebanner } = alertSlice.actions;
export default alertSlice.reducer;