import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#BE0000",
    color: "white",
    fontSize: "10px",
    border: "1px solid #FFF"
  },
}));


export const PricingCard = ({
  title,
  charges,
  bestDeal,
  btnTitle,
  features,
  duration,
  originalPrice,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const user = useSelector((state) => state.userProfile.userProfile);
  console.log("PP", user);

  return (
    <div className="h-full">
      {bestDeal ? (
        <div
          className="pricing-card-best-deal-wrapper h-full lg:-mt-14"
          style={{
            backgroundImage:
              "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bestDealBg.gif)",
            backgroundSize: "cover",
          }}
        >
          <div className="best-deal-heading py-4">Best Deal </div>
          <div
            className="pricing-card-best-deal h-full px-14 pt-16 mx-1"
            style={{
              backgroundImage:
                "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bestDealBg.gif)",
              backgroundSize: "cover",
            }}
          >
            <div className="pricing-card-title">{title}</div>
            <div className="pricing-card-charges pt-6">
              ${charges}{" "}
              <span className="pricing-card-per-month ">/{duration}</span>
            </div>
            <div
              className="pricing-card-charges p-1 px-1"
              style={{
                fontSize: "15px",
                textDecoration: "line-through",
                backgroundColor: "#AD0000",
                borderRadius: "7px",
              }}
            >
              ${originalPrice}{" "}
              <span
                className="pricing-card-per-month "
                style={{ fontSize: "15px" }}
              >
                /{duration} original price
              </span>
            </div>
            <div
              className="pricing-title pb-6 pt-2"
              style={{
                textAlign: "start",
                fontStyle: "italic",
                fontSize: "16px",
              }}
            >
              {" "}
              Save ${originalPrice - charges} on limited time offer{" "}
            </div>
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title={
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center", }}>
                  {user?.operational_status_type === "active" &&
                    user?.subscription &&
                    Object.keys(user.subscription).length > 0 ? (
                    <>
                      You have an active subscription.
                      <br />
                      Click to change plan.
                    </>
                  ) : (
                    ""
                  )}
                </div>
              }
              arrow
            >
              <Button
                className={
                  "pricing-card-button-layout-contained p-4 cursor-pointer"
                }
                onClick={() => {
                  user && Object.keys(user).length > 0 ?
                    navigate("/dashboard/payments")
                    :
                    navigate("/register")
                }

                }
              >
                <div className={"pricing-card-contained-button-title"}>
                  {btnTitle}
                </div>
              </Button>
            </Tooltip>
            <div className="pt-16">
              {features.map((feature) => (
                <div className="flex items-center space-x-2 pb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                  >
                    <circle
                      cx="5.5"
                      cy="5.5"
                      r="5.5"
                      fill="url(#paint0_linear_88_33)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_88_33"
                        x1="5.5"
                        y1="0"
                        x2="5.5"
                        y2="11"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#040095" />
                        <stop offset="1" stop-color="#0700FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div className="pricing-card-features"> {feature} </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="pricing-card px-14 py-16 h-full">
          <div className="pricing-card-title">{title}</div>
          <div className="pricing-card-charges pt-6">
            ${charges}{" "}
            <span className="pricing-card-per-month ">/{duration}</span>
          </div>
          <div
            className="pricing-card-charges p-1 px-2"
            style={{
              fontSize: "16px",
              textDecoration: "line-through",
              backgroundColor: "#AD0000",
              borderRadius: "7px",
            }}
          >
            ${originalPrice}{" "}
            <span
              className="pricing-card-per-month "
              style={{ fontSize: "16px" }}
            >
              /{duration} original price
            </span>
          </div>
          <div
            className="pricing-title pb-6 pt-2"
            style={{
              textAlign: "start",
              fontStyle: "italic",
              fontSize: "16px",
            }}
          >
            {" "}
            Save ${originalPrice - charges} on limited time offer{" "}
          </div>
          <Tooltip
            classes={{ tooltip: classes.customTooltip }}
            title={
              <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                {user?.operational_status_type === "active" &&
                 user?.organization_role_type === "admin" &&
                  user?.subscription &&
                  Object.keys(user.subscription).length > 0 ? (
                  <>
                    You have an active subscription.
                    <br />
                    Click to change plan.
                  </>
                ) : (
                  ""
                )}
              </div>
            }
            arrow
          >
            <Button
              className={"pricing-card-button-layout p-4 cursor-pointer"}
              onClick={() => {
                user && Object.keys(user).length > 0 ?
                  navigate("/dashboard/payments")
                  :
                  navigate("/register")
              }

              }
            >
              <div className={"pricing-card-button-title"}>{btnTitle}</div>
            </Button>
          </Tooltip>
          <div className="pt-16">
            {features.map((feature) => (
              <div className="flex items-center space-x-2 pb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                >
                  <circle
                    cx="5.5"
                    cy="5.5"
                    r="5.5"
                    fill="url(#paint0_linear_88_33)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_88_33"
                      x1="5.5"
                      y1="0"
                      x2="5.5"
                      y2="11"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#040095" />
                      <stop offset="1" stop-color="#0700FF" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="pricing-card-features"> {feature} </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
