import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tabs,
  Tab,
  Autocomplete,
  TextField,
  Chip,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import ConnectionTable from "./ConnectionTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getConnectedConnections,
  getConnectedCSV,
  getConnections,
  getQueuedConnections,
  getQueuedCSV,
  getWaitingConnections,
  getWaitingCSV,
} from "../../store/connectionSlice";
import MessageShow from "../messages/messageShow/messageShow";
import { getCampaigns } from "../../store/campaignSlice";
import { getTags } from "../../store/settingsSlice";
import { SearchOutlined } from "@mui/icons-material";
import MessageDialog from "../messages/messageShow/MessageDialog";
import PendingConnectionsTable from "./PendingConnectionsTable";
import RejectedConnectionsTable from "./RejectedConnectionsTable";

export const Connections = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaigns = useSelector((state) => state.campaign.campaigns);
  const connections = useSelector((state) => state.connection.connections);
  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1336px)"
  );
  const tags = useSelector((state) => state.settings.tags?.data);
  const [tagsUuids, setTagsUuids] = useState([]);
  const [inputvalue, setInputValue] = useState("");
  const [selectedFilter, setSelectedFilters] = useState([]);

  const [messageId, setMessageId] = useState(null);
  const [openMessageShow, setOpenMessageShow] = useState(null);

  const handleOpenMessage = (message) => {
    setMessageId(message.id);
    setOpenMessageShow(true);
  };

  const handleCloseMessage = () => {
    setMessageId(null);
    setOpenMessageShow(false);
  };

  const [type, setType] = useState("");
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const [value, setValue] = useState("one");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearTags = () => {
    setTagsUuids([]);
  };

  const handleChangeTags = (event, newValue) => {
    setTagsUuids(newValue);
    if (value === "one") {
      dispatch(
        getConnectedConnections({
          query: inputvalue,
          tag_uuids: newValue.map((tag) => tag.uuid),
          has_email: selectedFilter.includes("email"),
          has_phone_number: selectedFilter.includes("phone"),
          has_meeting: selectedFilter.includes("meeting"),
          has_replies: selectedFilter.includes("replies"),
        })
      );
    } else if (value === "two") {
      dispatch(
        getWaitingConnections({
          query: inputvalue,
          tag_uuids: newValue.map((tag) => tag.uuid),
          has_email: selectedFilter.includes("email"),
          has_phone_number: selectedFilter.includes("phone"),
        })
      );
    } else {
      dispatch(
        getQueuedConnections({
          query: inputvalue,
          tag_uuids: newValue.map((tag) => tag.uuid),
          has_email: selectedFilter.includes("email"),
          has_phone_number: selectedFilter.includes("phone"),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getConnectedConnections());
    dispatch(getTags({ tag_query: "", page: 1, per_page: 10 }));
  }, []);

  const formatHeader = (header) => {
    return header
      .split("_") // Split words by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(" "); // Join the words with space
  };

  const data = connections;

  const jsonToCSV = (json) => {
    const headers = [
      "full_name",
      "current_company",
      "current_job_title",
      "meeting_booked",
      "email",
      "phone_number",
      "tags",
    ];
    const formattedHeaders = headers.map((header) => formatHeader(header));

    const rows = json.map((obj) =>
      headers
        .map((header) => (obj[header] === null ? "" : obj[header]))
        .join(",")
    );

    return [formattedHeaders.join(","), ...rows].join("\n");
  };

  function exportToCsv(headers, data, fileName) {
    const csvData = data;
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "connections.csv";
    link.click();
    URL.revokeObjectURL(url); // Clean up the temporary object URL
  }

  const handleExportCsv = (csvData) => {
    exportToCsv({}, csvData, "connections-data.csv");
  };

  const toggleFilter = (key) => {
    setSelectedFilters((prevSelected) => {
      if (prevSelected.includes(key)) {
        return prevSelected.filter((filter) => filter !== key);
      } else {
        return [...prevSelected, key];
      }
    });
  };

  return (
    <div
      className={
        themeMode === "dark"
          ? "dashboard-bg h-full"
          : "dashboard-bg-light h-full"
      }
    >
      <div className="py-24 md:px-16 px-8">
        <div
          className={
            themeMode === "dark" ? "dashboard-title" : "dashboard-title-light"
          }
        >
          Connections
        </div>

        <div className="lg:flex justify-between items-center py-4">
          <div className="lg:flex items-center lg:space-x-6 w-full space-y-6 lg:space-y-0">
            <OutlinedInput
              value={inputvalue}
              onChange={(e) => {
                setInputValue(e.target.value);
                if (value === "one") {
                  dispatch(
                    getConnectedConnections({
                      query: e.target.value,
                      has_email: selectedFilter.includes("email"),
                      has_phone_number: selectedFilter.includes("phone"),
                      has_meeting: selectedFilter.includes("meeting"),
                      has_replies: selectedFilter.includes("replies"),
                      tag_uuids:
                        tagsUuids.length > 0
                          ? tagsUuids.map((tag) => tag.uuid)
                          : [],
                    })
                  );
                } else if (value === "two") {
                  dispatch(
                    getWaitingConnections({
                      query: e.target.value,
                      has_email: selectedFilter.includes("email"),
                      has_phone_number: selectedFilter.includes("phone"),
                      tag_uuids:
                        tagsUuids.length > 0
                          ? tagsUuids.map((tag) => tag.uuid)
                          : [],
                    })
                  );
                } else {
                  dispatch(
                    getQueuedConnections({
                      query: e.target.value,
                      has_email: selectedFilter.includes("email"),
                      has_phone_number: selectedFilter.includes("phone"),
                      tag_uuids:
                        tagsUuids.length > 0
                          ? tagsUuids.map((tag) => tag.uuid)
                          : [],
                    })
                  );
                }
              }}
              id="campaign-search"
              className={
                themeMode === "dark"
                  ? "dashboard-field"
                  : "dashboard-field-light"
              }
              size="small"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "& .MuiInputBase-input": {
                  color: themeMode === "dark" ? "white" : "black",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchOutlined
                      style={{
                        color: themeMode === "dark" ? "gray" : "darkgray",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Search"
            />

            <Autocomplete
              multiple
              freeSolo
              disablePortal
              value={tagsUuids}
              options={tags}
              getOptionLabel={(option) => option.title}
              onChange={handleChangeTags}
              sx={{ width: { md: 300, sm: 300, xs: "100%" } }}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    key={index}
                    label={option.title}
                    {...getTagProps({ index })}
                    // deleteIcon={
                    //   <CloseOutlined
                    //     style={{
                    //       color: themeMode === "dark" ? "white" : "black",
                    //     }}
                    //   />
                    // }
                    sx={{
                      backgroundColor:
                        themeMode === "dark" ? "gray" : "lightgray",
                      color: themeMode === "dark" ? "white" : "black",
                      "& .MuiChip-deleteIcon": {
                        color: "white",
                      },
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  placeholder="Search by Tag"
                  {...params}
                  className={
                    themeMode === "dark"
                      ? "dashboard-field"
                      : "dashboard-field-light"
                  }
                  size="small"
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent", // Change border color on hover
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent", // Change border color on hover
                      },
                    },
                    "&:hover .MuiInput-notched": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiTextFieldInput-notched": {
                      borderColor: "transparent",
                    },
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "black",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchOutlined
                          style={{
                            color: themeMode === "dark" ? "white" : "black",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
          </div>
          <div className="flex w-full lg:w-2/3 justify-end"></div>
        </div>

        {/* <div className="pt-4 flex flex-col lg:flex-row lg:space-y-0 space-y-4 lg:space-x-4 items-center"> */}
        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: {
              md: "flex-start",
              sm: "flex-start",
              xs: "center",
            },
            flexDirection: { md: "row", sm: "row", xs: "column" },
          }}
        >
          <Button
            id="email"
            onClick={() => {
              if (value === "one") {
                dispatch(
                  getConnectedConnections({
                    query: inputvalue,
                    has_email: !selectedFilter.includes("email"),
                    has_phone_number: selectedFilter.includes("phone"),
                    has_meeting: selectedFilter.includes("meeting"),
                    has_replies: selectedFilter.includes("replies"),
                    tag_uuids:
                      tagsUuids.length > 0
                        ? tagsUuids.map((tag) => tag.uuid)
                        : [],
                  })
                );
              } else if (value === "two") {
                dispatch(
                  getWaitingConnections({
                    query: inputvalue,
                    has_email: !selectedFilter.includes("email"),
                    has_phone_number: selectedFilter.includes("phone"),
                    tag_uuids:
                      tagsUuids.length > 0
                        ? tagsUuids.map((tag) => tag.uuid)
                        : [],
                  })
                );
              } else {
                dispatch(
                  getQueuedConnections({
                    query: inputvalue,
                    has_email: !selectedFilter.includes("email"),
                    has_phone_number: selectedFilter.includes("phone"),
                    tag_uuids:
                      tagsUuids.length > 0
                        ? tagsUuids.map((tag) => tag.uuid)
                        : [],
                  })
                );
              }
              toggleFilter("email");
            }}
            className={
              selectedFilter.includes("email")
                ? themeMode === "dark"
                  ? "table-filter-btn-contained p-2 px-4"
                  : "table-filter-btn-contained-light p-2 px-4"
                : themeMode === "dark"
                ? "table-filter-btn-outlined p-2 px-4"
                : "table-filter-btn-outlined-light p-2 px-4"
            }
            sx={{
              margin: {
                md: "0px 7px 0px 0px",
                sm: "0px 7px 0px 0px",
                xs: "5px 0px",
              },
              width: "fit-content",
            }}
          >
            <span
              className={
                themeMode === "dark" || selectedFilter.includes("email")
                  ? "table-filter-btn-text"
                  : "table-filter-btn-text-light"
              }
            >
              Has Email
            </span>
          </Button>
          <Button
            id="phone"
            onClick={() => {
              if (value === "one") {
                dispatch(
                  getConnectedConnections({
                    query: inputvalue,
                    has_phone_number: !selectedFilter.includes("phone"),
                    has_email: selectedFilter.includes("email"),
                    has_meeting: selectedFilter.includes("meeting"),
                    has_replies: selectedFilter.includes("replies"),
                    tag_uuids:
                      tagsUuids.length > 0
                        ? tagsUuids.map((tag) => tag.uuid)
                        : [],
                  })
                );
              } else if (value === "two") {
                dispatch(
                  getWaitingConnections({
                    query: inputvalue,
                    has_email: selectedFilter.includes("email"),
                    has_phone_number: !selectedFilter.includes("phone"),
                    tag_uuids:
                      tagsUuids.length > 0
                        ? tagsUuids.map((tag) => tag.uuid)
                        : [],
                  })
                );
              } else {
                dispatch(
                  getQueuedConnections({
                    query: inputvalue,
                    has_email: selectedFilter.includes("email"),
                    has_phone_number: !selectedFilter.includes("phone"),
                    tag_uuids:
                      tagsUuids.length > 0
                        ? tagsUuids.map((tag) => tag.uuid)
                        : [],
                  })
                );
              }
              toggleFilter("phone");
            }}
            className={
              selectedFilter.includes("phone")
                ? themeMode === "dark"
                  ? "table-filter-btn-contained p-2 px-4"
                  : "table-filter-btn-contained-light p-2 px-4"
                : themeMode === "dark"
                ? "table-filter-btn-outlined p-2 px-4"
                : "table-filter-btn-outlined-light p-2 px-4"
            }
            sx={{
              margin: { md: "0px 7px", sm: "0px 7px", xs: "5px 0px" },
              width: "fit-content",
            }}
          >
            <span
              className={
                themeMode === "dark" || selectedFilter.includes("phone")
                  ? "table-filter-btn-text"
                  : "table-filter-btn-text-light"
              }
            >
              Has Phone Number
            </span>
          </Button>
          {value === "one" && (
            <Button
              id="meeting"
              onClick={() => {
                if (value === "one") {
                  dispatch(
                    getConnectedConnections({
                      query: inputvalue,
                      has_meeting: !selectedFilter.includes("meeting"),
                      has_phone_number: selectedFilter.includes("phone"),
                      has_email: selectedFilter.includes("email"),
                      has_replies: selectedFilter.includes("replies"),
                      tag_uuids:
                        tagsUuids.length > 0
                          ? tagsUuids.map((tag) => tag.uuid)
                          : [],
                    })
                  );
                }
                toggleFilter("meeting");
              }}
              className={
                selectedFilter.includes("meeting")
                  ? themeMode === "dark"
                    ? "table-filter-btn-contained p-2 px-4"
                    : "table-filter-btn-contained-light p-2 px-4"
                  : themeMode === "dark"
                  ? "table-filter-btn-outlined p-2 px-4"
                  : "table-filter-btn-outlined-light p-2 px-4"
              }
              sx={{
                margin: { md: "0px 7px", sm: "0px 7px", xs: "5px 0px" },
                width: "fit-content",
              }}
            >
              <span
                className={
                  themeMode === "dark" || selectedFilter.includes("meeting")
                    ? "table-filter-btn-text"
                    : "table-filter-btn-text-light"
                }
              >
                Has Meeting
              </span>
            </Button>
          )}
          {value === "one" && (
            <Button
              id="replies"
              onClick={() => {
                if (value === "one") {
                  dispatch(
                    getConnectedConnections({
                      query: inputvalue,
                      has_replies: !selectedFilter.includes("replies"),
                      has_meeting: selectedFilter.includes("meeting"),
                      has_phone_number: selectedFilter.includes("phone"),
                      has_email: selectedFilter.includes("email"),
                      tag_uuids:
                        tagsUuids.length > 0
                          ? tagsUuids.map((tag) => tag.uuid)
                          : [],
                    })
                  );
                }
                toggleFilter("replies");
              }}
              className={
                selectedFilter.includes("replies")
                  ? themeMode === "dark"
                    ? "table-filter-btn-contained p-2 px-4"
                    : "table-filter-btn-contained-light p-2 px-4"
                  : themeMode === "dark"
                  ? "table-filter-btn-outlined p-2 px-4"
                  : "table-filter-btn-outlined-light p-2 px-4"
              }
              sx={{
                margin: { md: "0px 7px", sm: "0px 7px", xs: "5px 0px" },
                width: "fit-content",
              }}
            >
              <span
                className={
                  themeMode === "dark" || selectedFilter.includes("replies")
                    ? "table-filter-btn-text"
                    : "table-filter-btn-text-light"
                }
              >
                Has Replies
              </span>
            </Button>
          )}
          <Button
            onClick={() => {
              clearTags();
              setInputValue("");
              setSelectedFilters([]);
              if (value === "one") {
                dispatch(getConnectedConnections());
              } else if (value === "two") {
                dispatch(getWaitingConnections());
              } else {
                dispatch(getQueuedConnections());
              }
            }}
            className={
              themeMode === "dark"
                ? "dashboard-clear-filters cursor-pointer"
                : "dashboard-clear-filters-light cursor-pointer"
            }
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: { md: "15px", sm: "15px", xs: "0px" },
              color: "#FFF",
              "&:hover": { background: "transparent" },
            }}
          >
            Clear Filters
          </Button>
        </Grid>
        {/* </div> */}
        <div className="flex w-full justify-end">
          {" "}
          <Box
            my={{ md: 0, sm: 0, xs: 3 }}
            sx={{ position: "relative", display: "inline-block" }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                if (value === "one") {
                  dispatch(getConnectedCSV()).then((resp) => {
                    if (resp?.payload?.status === 200) {
                      handleExportCsv(resp.payload.data);
                    }
                  });
                } else if (value === "two") {
                  dispatch(getWaitingCSV()).then((resp) => {
                    if (resp?.payload?.status === 200) {
                      handleExportCsv(resp.payload.data);
                    }
                  });
                } else {
                  dispatch(getQueuedCSV()).then((resp) => {
                    if (resp?.payload?.status === 200) {
                      handleExportCsv(resp.payload.data);
                    }
                  });
                }
              }}
              // onClick={handleExportCsv}
              sx={{
                fontSize: "13px",
                backgroundColor: "#4a0000",
                color: "#fff",
                border: "1px solid #FF2B2B",
                width: "100%",
                padding: "4px 10px",
                borderRadius: "7px",
                boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#600000",
                },
              }}
            >
              Download CSV
              <img
                src="/export.png"
                alt="Download Icon"
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </Box>
        </div>

        <div
          className={
            themeMode === "dark"
              ? "p-4 px-8 my-8 dashboard-table-layout"
              : "p-4 px-8 my-8 dashboard-table-layout-light"
          }
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="secondary tabs example"
            TabIndicatorProps={{
              style: {
                transitionDuration: "0.1s", // Speed up the indicator change
              },
            }}
            sx={{
              "& .MuiTab-root": {
                color: themeMode === "dark" ? "#FFF" : "black",
                fontWeight: "bold",
                color: themeMode === "dark" ? "#FFF" : "black",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textTransform: "none",
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeMode === "dark" ? "#FFF" : "black",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              },
              "& .MuiTabs-indicator": {
                backgroundColor:
                  themeMode === "dark" ? "whitesmoke" : "#0C0404",
              },
            }}
          >
            <Tab
              disableRipple
              value="one"
              label="Connected"
              onClick={() => {
                clearTags();
                setInputValue("");
                setSelectedFilters([]);
                dispatch(getConnectedConnections());
              }}
            />
            <Tab
              disableRipple
              value="two"
              label="Waiting To be Connected"
              onClick={() => {
                clearTags();
                setInputValue("");
                setSelectedFilters([]);
                dispatch(getWaitingConnections());
              }}
            />
            <Tab
              disableRipple
              value="three"
              label="Queued for Connection Request"
              onClick={() => {
                clearTags();
                setInputValue("");
                setSelectedFilters([]);
                dispatch(getQueuedConnections());
              }}
            />
          </Tabs>
          <div className="">
            {value === "one" && (
              <ConnectionTable
                openMessageShow={handleOpenMessage}
                inputvalue={inputvalue}
                selectedFilter={selectedFilter}
                tag_uuids={tagsUuids}
              />
            )}
            {value === "two" && (
              <PendingConnectionsTable
                openMessageShow={handleOpenMessage}
                inputvalue={inputvalue}
                selectedFilter={selectedFilter}
                tag_uuids={tagsUuids}
              />
            )}
            {value === "three" && (
              <RejectedConnectionsTable
                openMessageShow={handleOpenMessage}
                inputvalue={inputvalue}
                selectedFilter={selectedFilter}
                tag_uuids={tagsUuids}
              />
            )}
          </div>
        </div>
      </div>
      <MessageDialog
        openMessageShow={openMessageShow}
        closeMessageShow={handleCloseMessage}
        messageId={messageId}
      />
    </div>
  );
};
