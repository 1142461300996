import { Button, Grid, Typography, Divider } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import OnboardingNav from "./OnboardingNav";
import { useNavigate } from "react-router-dom";
import { ConfirmDetailsForm } from "./ConfirmDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getConfirmDetails } from "../store/onboardingSlice";
import OnboardingErrorDialog from "./OnboardingErrorDialog";
import moment from "moment";
import { generatePdfUsingHtmltoImage } from "../../utils/globalFunctions";

const bkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeFinal.gif";
//"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeBkg.png";
const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

const ConfirmDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [animationClass, setAnimationClass] = useState("page-enter");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };
  useEffect(() => {
    setAnimationClass("page-enter");
    return () => {};
  }, []);

  const handlePageLeave = () => {
    generatePdfUsingHtmltoImage(
      document.getElementById("confirm_details"),
      "confirm_details",
      dispatch
    );
    setAnimationClass("page-exit");
    setTimeout(() => {
      navigate("/set-password");
    }, 500);
  };

  useEffect(() => {
    dispatch(getConfirmDetails());
  }, []);

  const tomorrow = moment().add(1, "days");

  return (
    <Grid
      className="flex justify-center"
      id="confirm_details"
      sx={{
        //backgroundImage: `url(${bkg})`,
        backgroundImage:
          "linear-gradient(to right, #b03302 0%, #FF3001 20%, #FF3001 80%, #b03302 100%)",
        backgroundSize: "cover",
      }}
    >
      <OnboardingNav selected={2} />
      <div className={`flex items-start w-full px-12 my-16 ${animationClass}`}>
        <div className="lg:flex w-full mt-16">
          <div className="lg:w-4/6">
            <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
              <div className="sub-agreement-heading pb-8">
                Facoon Labs Growth and Automation Subscription Agreement
              </div>
              <div className="sub-agreement-subheading pr-6 pb-6">
                This Subscription Agreement is made effective as of{" "}
                {moment().format("MM/DD/YYYY")}
                (the "Effective Date") by and between Facoon Labs LLC ("Facoon
                Labs") and the Client listed below.
                <br />
                Facoon Labs has developed a software-as-a-service platform
                designed to automate direct social engagement marketing and
                support businesses in scaling their operations. The Client
                wishes to subscribe to the Service (as defined below).
                <br />
                This Agreement outlines the terms and conditions under which
                Facoon Labs will deliver the Service. The Client's use and
                access to the Service is strictly governed by the terms set
                forth in this Agreement, which supersedes any prior agreements
                or understandings between the parties.
                <br /> <br />
                <b>Effective Date:</b> As of the date above. <br />
                <b>Negotiated Fees and Terms</b>
                <br /> <br />
                Facoon Labs subscription plans require a 30-day cancellation
                notice.
                <br />
                To avoid being billed for the next term, you must cancel at
                least 30 days before your billing date.
                <br />
                This applies to all subscription types: Monthly, Semi-Annual,
                and Annual. Failure to provide notice will result in being
                billed for an additional month at the monthly rate.
                <br />
                For monthly plans, there is a minimum commitment of 6 months. No
                refunds (including partial or pro-rated refunds) will be given
                for early termination.
                <br />
                If you cancel early, depending on your subscription type and
                timing, additional charges may still apply. Early termination
                will prevent further charges from being processed, but you will
                retain access to the service for the remainder of your
                subscription period.
                <br />
                Upon becoming a client, you will receive access to proprietary
                processes and information. Therefore, no refunds will be
                provided once onboarding is completed. Monthly subscribers are
                required to fulfill the entire 6-month commitment outlined in
                this contract.
                <br />
                By subscribing, you authorize a recurring charge on your
                selected payment method, plus any applicable taxes. Charges will
                appear on your statement as Facoon Labs.
                <br /> <br />
                <b>Service Details</b>
                <br /> <br />
                <ul>
                  <li>License to use the Facoon Labs platform</li>
                  <li>Platform training and support</li>
                  <li>Compliance with LinkedIn's daily invite parameters</li>
                  <li>
                    Offer expires at{" "}
                    {tomorrow
                      .set({ hour: 17, minute: 0 }) // Set time to 5:00 PM
                      .format("h:mm A [MST] [on] MM/DD/YYYY")}
                  </li>
                </ul>
                <br />
                This sale is governed by the Facoon Labs Terms of Sale,
                effective on the date the purchase order is received. These
                terms are incorporated into this Agreement by reference. Facoon
                Labs limits acceptance to its Terms of Service and rejects any
                additional or different terms proposed by the purchaser.
                <br />
                By signing, I certify that I have read and agree to the terms
                set forth in this agreement and those posted at
                <b> www.facoonlabs.com.</b> <br />
              </div>
            </div>
          </div>
          <div className="lg:w-full lg:px-16">
            <div className="confirm-details-heading ">
              Confirm your details{" "}
            </div>
            <div className="sub-agreement-subheading pb-4">
              Have a look into your personal details and confirm if they are
              good to go.{" "}
            </div>

            <ConfirmDetailsForm
              handlePageLeave={handlePageLeave}
              handleClickOpen={handleClickOpen}
            />
          </div>
        </div>
      </div>
      <OnboardingErrorDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
    </Grid>
  );
};
export default ConfirmDetails;
