import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Menu,
  Divider,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  notifMarkAllAsRead,
  notifMarkOneAsRead,
} from "../../store/notificationSlice";
import { getChat } from "../../store/messagesSlice";
import { success } from "../../store/alertSlice";

const NotificationMenu = ({
  notifBoxVisible,
  closeNotif,
  handleOpenMessage,
}) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [page, setPage] = useState(1);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const notificationMeta = useSelector(
    (state) => state.notification.notificationMeta
  );
  const notificationsLoading = useSelector(
    (state) => state.notification.notificationsLoading
  );

  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1175px)"
  );
  const [filter, setFilter] = useState("all");
  const filteredNotifications = notifications?.filter((item) => {
    if (filter === "all") return true;
    if (filter === "read") return item.read === true;
    if (filter === "unread") return item.read === false;
    return true;
  });

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  // Scroll event handler
  const handleScroll = (event) => {
    const element = event.target;

    // Check if the user has scrolled to the bottom
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      console.log("You have scrolled to the end of the Grid!");
      if (page + 1 > notificationMeta.total_pages) {
        return;
      } else {
        dispatch(getNotifications({ page: page + 1 })).then((resp) => {
          if (resp?.payload?.status === 200) {
            setPage(page + 1);
          }
        });
      }
    }
  };

  return (
    <Menu
      anchorEl={notifBoxVisible}
      open={notifBoxVisible}
      onClose={closeNotif}
      PaperComponent={Paper}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: {
          md: "100% !important",
          sm: "100% !important",
          xs: "100% !important",
        },
        padding: "10px 18px",
        "& .MuiPaper-root": {
          backgroundColor: themeMode === "dark" ? "#0d0d0dED" : "#0F0F0F99",
          boxShadow: "0px 8px 5px 0px #00000040",
          border:
            themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #ECECEC",
          color: "#FFF",
          display: "flex",
          alignItems: "flex-end",
          borderRadius: "15px",
          padding: { md: "10px 20px", sm: "10px 20px", xs: "10px 10px" },
          backdropFilter: "blur(20px)",
          width: "fit-content",
          maxWidth: {
            // md: isCustomRange ? "70vh" : "60vh",
            md: "70vh",
            sm: "70vh",
            xs: "42vh",
          },
          minWidth: {
            md: "70vh",
            sm: "48vh",
            xs: "40vh",
          },
        },
      }}
    >
      <Grid sx={{ width: "100%" }}>
        <div className="md:flex justify-between space-x-4 items-center w-full">
          <Typography
            className="flex"
            sx={{
              fontSize: "28px",
              fontWeight: "700",
              textAlign: "left",
              margin: { md: "0px", sm: "0px", xs: "0px 10px" },
            }}
          >
            Notifications{" "}
          </Typography>

          <Grid
            sx={{
              display: "flex",
              justifyContent: {
                md: "flex-end",
                sm: "flex-end",
                xs: "flex-end",
              },
            }}
          >
            {filter === "all" || filter === "unread" ? (
              <button
                onClick={() => {
                  dispatch(notifMarkAllAsRead()).then((resp) => {
                    if (resp?.payload?.status === 200) {
                      dispatch(getNotifications());
                    }
                  });
                }}
                style={{
                  border: "1px solid transparent",
                  background: "#E31D1D",
                  borderRadius: "10px",
                  padding: "2px 10px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "14px" },
                    textWrap: "nowrap",
                  }}
                >
                  Mark all as read
                </Typography>
              </button>
            ) : (
              <button
                style={{
                  //display:{md:"flex",sm:"flex",xs:"none"},
                  border: "1px solid transparent",
                  background: "transparent",
                  borderRadius: "10px",
                  padding: "2px 10px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "14px" },
                    textWrap: "nowrap",
                    color: "transparent",
                  }}
                >
                  Mark all as read
                </Typography>
              </button>
            )}
          </Grid>
        </div>

        <Grid
          item
          my={{ md: 2, sm: 2, xs: 1 }}
          md={9}
          sm={12}
          xs={9}
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: {
              md: "flex-start",
              sm: "space-evenly",
              xs: "space-between",
            },
            textAlign: "flex-start",
            flexWrap: "nowrap",
          }}
        >
          <button
            onClick={() => setFilter("all")}
            style={{
              border:
                filter === "all" ? "1px solid #FFF" : "1px solid transparent",
              background: filter === "all" ? "#262626" : "transparent",
              borderRadius: "10px",
              display: "flex",
              padding: "2px 10px",
              width: "fit-content",
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "18px", sm: "18px", xs: "13px" },
              }}
              className="px-2"
            >
              All
            </Typography>
          </button>
          <button
            onClick={() => setFilter("read")}
            style={{
              border:
                filter === "read" ? "1px solid #FFF" : "1px solid transparent",
              background: filter === "read" ? "#262626" : "transparent",
              borderRadius: "10px",
              padding: "2px 5px",
              width: "fit-content",
              display: "flex",
              margin: { md: "0px 10px", sm: "0px 10px", xs: "0px 0px" },
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            <Typography
              // mx={{ md: 2, sm: 2, xs: 1 }}
              sx={{
                fontSize: { md: "18px", sm: "18px", xs: "13px" },
              }}
              className="px-2"
            >
              Read
            </Typography>
          </button>
          <button
            onClick={() => setFilter("unread")}
            style={{
              border:
                filter === "unread"
                  ? "1px solid #FFF"
                  : "1px solid transparent",
              background: filter === "unread" ? "#262626" : "transparent",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              padding: { md: "2px 8px", sm: "2px 8px", xs: "2px 2px" },
              width: "fit-content",
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            <Typography
              mx={{ md: 0, sm: 0, xs: 1 }}
              sx={{
                fontSize: { md: "18px", sm: "18px", xs: "13px" },
              }}
              className="px-2"
            >
              Unread
            </Typography>
          </button>
        </Grid>
        <Grid
          onScroll={handleScroll}
          mt={1}
          sx={{
            overflowY: "auto",
            maxHeight: "45vh",
            minWidth: {
              lg: "66vh",
              md: "60vh",
              sm: "40vh",
              xs: "40vh",
            },
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              width: 0,
              background: "transparent",
            },
          }}
        >
          {filteredNotifications?.map((item, index) => (
            <>
              <Grid
              onClick={() => {
                if (
                  item.notificationable_uuid &&
                  item.notificationable_type === "Chat"
                ) {
                  dispatch(getChat(item.notificationable_uuid)).then(() => {
                    handleOpenMessage(item);
                  });
                }
                if (!item.read) {
                  dispatch(notifMarkOneAsRead(item.uuid)).then((resp) => {
                    if (resp?.payload?.status === 200) {
                      dispatch(getNotifications());
                    }
                  });
                }
              }}
              key={index}
              py={1}
              my={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                //borderBottom: "1px solid #FFFF",
                justifyContent: "space-between",
                paddingRight: "10px",
                width: "100%",
                borderRadius:"8px",
                "&:hover":{
                  background: themeMode === "dark" ? "#BE000022" : "#BE0000AA",
                  backdropFilter:"35px",
                }
              }}
              className="cursor-pointer  "
            >
              <Grid item md={9} sm={9} xs={9}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: !item.read ? "600" : "400",
                    textAlign: "left",
                    padding: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {item.content}{" "}
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                sm={2}
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  sx={{
                    display: !item.read ? "flex" : "none",
                    width: "13px",
                    height: "13px",
                    backgroundColor: "#E31D1D",
                    border:
                      themeMode === "dark"
                        ? "1px solid #E31D1D"
                        : "1px solid #FFF",
                    borderRadius: "50%",
                  }}
                ></Grid>
              </Grid>
              
              </Grid>
              <Divider orientation="horizontal" color="#FFF" my="3px" sx={{height:"1px"}}/>
            </>
          ))}
          <div className="flex w-full justify-center">
            {notificationsLoading && (
              <span>
                <CircularProgress
                  className="ml-1"
                  sx={{ color: "white" }}
                  size={16}
                />
              </span>
            )}
          </div>
        </Grid>
      </Grid>
    </Menu>
  );
};

export default NotificationMenu;
