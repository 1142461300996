import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  connections: [],
  connectionMeta: {},
  connectedConnections: [],
  connectedConnectionMeta: {},
  waitingConnections: [],
  waitingConnectionMeta: {},
  queuedConnections: [],
  queuedConnectionMeta: {},
};

export const getConnections = createAsyncThunk(
  "connection/getConnections",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/connections`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 10,
          tag_uuids: searchQuery ? searchQuery.tag_uuids : [],
        },
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getConnectedConnections = createAsyncThunk(
  "connection/getConnectedConnections",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/connections/connected`,
        {
          params: {
            query: searchQuery ? searchQuery.query : "",
            has_phone_number: searchQuery
              ? searchQuery.has_phone_number
              : false,
            has_email: searchQuery ? searchQuery.has_email : false,
            has_meeting: searchQuery ? searchQuery.has_meeting : false,
            has_replies: searchQuery ? searchQuery.has_replies : false,
            page: searchQuery ? searchQuery.page : 1,
            per_page: searchQuery ? searchQuery.per_page : 10,
            tag_uuids: searchQuery ? searchQuery.tag_uuids : [],
          },
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getWaitingConnections = createAsyncThunk(
  "connection/getWaitingConnections",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/connections/waiting_to_be_connected`,
        {
          params: {
            query: searchQuery ? searchQuery.query : "",
            tag_uuids: searchQuery ? searchQuery.tag_uuids : [],
            has_phone_number: searchQuery
              ? searchQuery.has_phone_number
              : false,
            has_email: searchQuery ? searchQuery.has_email : false,
            page: searchQuery ? searchQuery.page : 1,
            per_page: searchQuery ? searchQuery.per_page : 10,
          },
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getQueuedConnections = createAsyncThunk(
  "connection/getQueuedConnections",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/connections/queued_for_connection_request`,
        {
          params: {
            query: searchQuery ? searchQuery.query : "",
            tag_uuids: searchQuery ? searchQuery.tag_uuids : [],
            has_phone_number: searchQuery
              ? searchQuery.has_phone_number
              : false,
            has_email: searchQuery ? searchQuery.has_email : false,
            page: searchQuery ? searchQuery.page : 1,
            per_page: searchQuery ? searchQuery.per_page : 10,
          },
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getConnectedCSV = createAsyncThunk(
  "connection/getConnectedCSV",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/connections/connected.csv`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getWaitingCSV = createAsyncThunk(
  "connection/getWaitingCSV",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/connections/waiting_to_be_connected.csv`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getQueuedCSV = createAsyncThunk(
  "connection/getQueuedCSV",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/connections/queued_for_connection_request.csv`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    },
    setQuickResponse: (state, action) => {
      state.quickResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConnections.fulfilled, (state, action) => {
      state.connections = action.payload?.data?.data;
      state.connectionMeta = action.payload?.data?.meta;
    });
    builder.addCase(getConnectedConnections.fulfilled, (state, action) => {
      state.connectedConnections = action.payload?.data?.data;
      state.connectedConnectionMeta = action.payload?.data?.meta;
    });
    builder.addCase(getWaitingConnections.fulfilled, (state, action) => {
      state.waitingConnections = action.payload?.data?.data;
      state.waitingConnectionMeta = action.payload?.data?.meta;
    });
    builder.addCase(getQueuedConnections.fulfilled, (state, action) => {
      state.queuedConnections = action.payload?.data?.data;
      state.queuedConnectionMeta = action.payload?.data?.meta;
    });
  },
});

export const { setTag, setQuickResponse } = connectionSlice.actions;

export default connectionSlice.reducer;
