import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
import api from "../services/api";
const { REACT_APP_API_URL } = process.env;

const initialState = {
    paymentMethods: [],
    primaryPaymentMethod:"",
};

export const getPaymentMethods = createAsyncThunk(
    "/payment_methods",
    async ({ page = 1, per_page = 10 }, { dispatch }) => { 
        try {
            const res = await axios.get(`${REACT_APP_API_URL}/payment_methods`, {
                params: {
                  page: page ? page : 1,
                  per_page: per_page ? per_page : 10,
                },
            });
            return { data: res?.data, status: res?.status, meta: res?.meta };
        } catch (err) {
            console.error('API Request Failed:', err.response ? err.response.data : err.message);
            dispatch(error(err.message));
            throw err; 
        }
    }
);

export const addPaymentMethod = createAsyncThunk(
    "payment_methods/addPaymentMethod",
    async (payment_method, { dispatch, rejectWithValue }) => {
        try {
            const res = await api.post(`/payment_methods`, { payment_method });
            return { data: res?.data, status: res?.status };
        } catch (err) {
            dispatch(error(err.message));
            console.error('Error in addPaymentMethod:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const payNowPaymentMethod = createAsyncThunk(
    "payment_methods/payNowPaymentMethod",
    async (payment, { dispatch, rejectWithValue }) => {
        try {
            const res = await api.post(`/pay_now`, { payment });
            return { data: res?.data, status: res?.status };
        } catch (err) {
            dispatch(error(err.message));
            console.error('Error in payNowPaymentMethod:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const setPaymentAsDefault = createAsyncThunk(
    "payment_methods/set_default",
    async (paymentMethods, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.put(
                `${REACT_APP_API_URL}/payment_methods/set_default/${paymentMethods}`);
            return { data: res?.data, status: res?.status };
        } catch (err) {
            dispatch(error(err.message));
            console.error('Error in setPaymentAsDefault:', err);
            return rejectWithValue(err.message);
        }
    }
);

export const deletePaymentMethod = createAsyncThunk(
    "payment_methods/delete_paymentMethod",
    async (paymentMethod, { dispatch, getState }) => {
        try {
            const res = await axios.delete(`${REACT_APP_API_URL}/payment_methods/${paymentMethod}`);
            return { data: res.data, status: res.status };
        } catch (err) {
            dispatch(error(err.message));
            console.error(err);
            return null;
        }
    }
);

export const managePayment = createAsyncThunk(
    "payment_methods/manage_payments",
    async (paymentMethod, { dispatch, getState }) => {
        try {
            const res = await axios.put(
                `${REACT_APP_API_URL}/payment_methods/manage_payments`);
            return { data: res?.data, status: res?.status };
        } catch (err) {
            dispatch(error(err.message));
            console.error('Error in managePayment:', err);
            return null;
        }
    }
)

export const paymentMethodSlice = createSlice({
    name: "payments_method",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
                    state.paymentMethods = action.payload.data;
        })
        builder.addCase(getPaymentMethods.rejected, (state, action) => {
            console.error('Failed to fetch payment methods:', action.error.message);
        });

        builder.addCase(setPaymentAsDefault.fulfilled, (state, action) => {
            const updatedMethod = action.payload.data;
            
            // state.paymentMethods.data = state.paymentMethods?.data?.map((method) =>
            //     method.uuid === updatedMethod.uuid
            //         ? { ...method, default_method: true }
            //         : { ...method, default_method: false }
            // );
            state.primaryPaymentMethod = action.payload.data;
        });

        builder.addCase(setPaymentAsDefault.rejected, (state, action) => {
            console.error('Payment Default Methods Rejected:', action.error.message);
            console.error('Error Details:', action.payload);
        })

        builder.addCase(setPaymentAsDefault.pending, () => {
                console.log('Payment Default Methods Pending...'); 
            });
        builder.addCase(deletePaymentMethod.fulfilled, (state, action) => {
            const deletedMethodUuid = action;
            // console.log("Delete Method", deletedMethodUuid);

            // state.paymentMethods.data = state.paymentMethods?.data?.filter(
            //     (method) => method.uuid !== deletedMethodUuid
            // );
        });
        builder.addCase(deletePaymentMethod.pending, () => {
            console.log('Deleting Payment Method Pending...');
        });
        builder.addCase(deletePaymentMethod.rejected, (state, action) => {
            console.error('Payment Delete Methods Rejected:', action.error.message);
            console.error('Error Details:', action.payload);
        })


    },
});



export const { } = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;