import React from "react";
import { Alert, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removebanner } from "../app/store/alertSlice";

const TopNotificationBanner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bannerMessage = useSelector((state) => state.alert?.bannerMessage); 
  const bannerUrl = useSelector((state) => state.alert?.bannerUrl); 

  const handleClose = () => {
    dispatch(removebanner()); 
  };

  if (!bannerMessage) return null;

  return (
    <Stack
      sx={{
        width: "100%",
        cursor: "pointer",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Alert
        severity="warning"
        onClose={handleClose}
        sx={{
          padding: "1px 1px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
            onClick={() => bannerUrl && navigate(bannerUrl)}
        >
          {bannerMessage}
        </Typography>
      </Alert>
    </Stack>
  );
};

export default TopNotificationBanner;