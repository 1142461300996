import { useDispatch, useSelector } from "react-redux";
import { React,useState, useEffect } from "react";
import { Grid, Typography, Toolbar, Tabs, Tab, Button, Link, InputAdornment, FormControl, FormLabel, RadioGroup,  Radio, TextField, FormControlLabel, Checkbox, IconButton,useMediaQuery, Box, FormHelperText, Skeleton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { error as errorMsg, success, removebanner } from "../../store/alertSlice.js";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { payNowPaymentMethod } from "../../store/paymentMethodSlice.js";
import { useNavigate } from "react-router-dom";

const masterCard = "https://gistcdn.githack.com/hamza-smh/5d9cb5c8936222b6c436169b73d4cbb0/raw/e2e3018e6bbab78ab707bbe151807c949939d6e9/masterCard.svg"
const stripeBlue = "https://gistcdn.githack.com/hamza-smh/7c894ab172c3f48094db88a14e1bcb21/raw/9d48be7b5ecf1da4fed962b092f55855762bf208/stripeBlue.svg"
const stripe = "https://gistcdn.githack.com/hamza-smh/9221ed5a952f3290dac0578867ece369/raw/2bff5e46f5ad201fed9c169901e61e545d77def6/stripeWhite.svg";
const paypal = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/paypalFull.png"
const visa = "https://gistcdn.githack.com/hamza-smh/b458542799dfd5c25362ee15c6395556/raw/ef73c34fd8bb8ffc9da8ec68ac66f60771dfa697/visa.svg"
const visaWhite = "https://gistcdn.githack.com/hamza-smh/9c5c05db01cf2df85f1405cd900a14b4/raw/ab9e2a5d912271ec06274c8b20fe2bf75402bab1/visaWhite.svg"

const method = [
    { label: 'Stripe (Credit/Debit Card)', width: '50%', left: '0%' },
    { label: 'Paypal', width: '50%', left: '50%' },
];
const Animation = styled("div")(({ width, left }) => ({
    position: "absolute",
    height: "100%",
    top: 0,
    zIndex: 0,
    background: "#E50000",
    borderRadius: "30px",
    border: "1px solid #FFF",
    transition: "all .5s ease 0s",
    width: width,
    left: left,
}));

const defaultValues = {
    email: "",
    password: "",
    cardHolderName: "",
    cardNumber: "",
    cardExpiry: "",
    cvc: "",
    addressLine1: "",
    postalCode: "",
    addressLine2: "",
    city: "",
    country: "",
};

const PayNowPaymentMethod = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [activeTab, setActiveTab] = useState(0);
    const [hoverTab, setHoverTab] = useState(null);
    const [selectedTab, setSelectedTab] = useState("Stripe (Credit/Debit Card)");
    const [showPassword, setShowPassword] = useState(false);
    const isCustomRange = useMediaQuery(
        "(min-width:900px) and (max-width:1336px)"
    );
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.userProfile.userProfile);
    const createSchema = (tab) => yup.object().shape({
        email: tab === 'Paypal' ? yup.string().required('Email is required') : yup.string(),
        password: tab === 'Paypal' ? yup.string().required('Password is required') : yup.string(),
        cardHolderName: tab === 'Stripe (Credit/Debit Card)' ? yup.mixed().required('Card Holder Name is required') : yup.mixed(),
        cardNumber: tab === 'Stripe (Credit/Debit Card)' ? yup.mixed().required('Card Number is required') : yup.mixed(),
        cardExpiry: tab === 'Stripe (Credit/Debit Card)' ? yup.mixed().required('Card Expiry is required') : yup.mixed(),
        cvc: tab === 'Stripe (Credit/Debit Card)' ? yup.mixed().required('CVC is required') : yup.mixed(),
    });
    
    const [schema, setSchema] = useState(createSchema(selectedTab));

    const { handleSubmit, register, reset, control, watch, formState } = useForm({
        defaultValues,
        mode: "all",
        resolver: yupResolver(schema),
    });

    const { errors } = formState;

    useEffect(() => {
        reset();
        setSchema(createSchema(selectedTab));
    }, [selectedTab]);

    useEffect(() => {
        const currentPath = window.location.pathname;
        const matchingTab = method.findIndex((item) => item.link === currentPath);
        if (matchingTab !== -1) {
            setActiveTab(matchingTab);
        }
        dispatch(removebanner());
    }, []);

    const getAnimationStyle = () => {
        const tab = hoverTab !== null ? hoverTab : activeTab;
        return {
            width: method[tab]?.width,
            left: method[tab]?.left,
        };
    };

    const [selectedValue, setSelectedValue] = useState('');

    const handleChangeRadio = (event) => {
        setSelectedValue(event.target.value);
    };


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleChange = (event, newValue) => {
        // setActiveTab(newValue);
        // setHoverTab(null);
        // setSelectedTab(event.target.value);
    };
    const handleMouseEnter = (index) => {
        setHoverTab(index);
    };

    const handleMouseLeave = () => {
        setHoverTab(null);
    };

    async function onSubmit(data) {
        if(user?.operational_status_type === "inactive_due_to_payment" && user?.organization_role_type === "admin"){
            setLoading(true);

            if(selectedTab === "Stripe (Credit/Debit Card)"){
                if (!stripe || !elements) {
                    setLoading(false);
                    return;
                }
                try {
                    const cardElement = elements?.getElement(CardNumberElement);
                    const { token, error } = await stripe.createToken(cardElement);
                    if (!token) {
                        dispatch(errorMsg("Invalid card details"));
                        if (error && error?.message) {
                            console.error(error.message);
                            dispatch(errorMsg(error.message));
                        }
                        setLoading(false);
                        return;
                    }
                    const payment = {
                        source: token?.id,
                        payment_method_type: "stripe",
                        default_method: data?.default_method,
                        currency: "usd",
                        cardHolderName: data?.cardHolderName,
                        addressLine1: data?.addressLine1,
                        postalCode: data?.postalCode,
                        addressLine2: data?.addressLine2,
                        city: data?.city,
                        country: data?.country,   
                    }
                    dispatch(payNowPaymentMethod(payment)).then((resp) => {
                        setLoading(false);
                        if (resp?.payload?.status === 201) {
                            dispatch(success("payment sucessfull and new payment method added!"));
                            navigate(`/dashboard/payments/manage-payment-methods`);
                        } 
                    });
                } catch (error) {
                    console.error("Error:", error);
                    setLoading(false);
                }
            }
        }
    }


    return (
        <div>
            <div className="pt-24 pb-6 md:px-16 px-8">
                <div
                    className={`dashboard-title ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    Add and Pay with New Payment Method
                </div>
                <div
                    className={`dashboard-subtitle py-4 ${themeMode === "dark" ? "text-light" : "text-dark"
                        }`}
                >
                    Choose your preferred payment method.{" "}
                </div>
            </div>
            <div className="md:px-16 xs:px-2 px-8">
                <Toolbar
                    className="paymentMethod-slider"
                    sx={{
                        background: themeMode === "dark" ? "#530000" :"#D05F5F",
                        border:themeMode==="dark"?" 1px solid #FFF":"1px solid #000",
                        display: {md:"flex",sm:"flex",xs:"none"},
                        width: { md:isCustomRange?"75%":"50%", sm: "75%", xs: "100%" },
                        overflowX: { md: "hidden", sm: "auto" },
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        variant="fullWidth"
                        sx={{
                            width: "100%",
                        }}
                    >
                        {method.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                style={{ minWidth: { md: tab.width * 1.5, sm: tab.width * 0.5 } }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave(index)}
                                // onClick={() => setSelectedTab(tab.label)}
                                disableRipple
                                className="paymentItem"
                                sx={{
                                    width: "fit-content",
                                    height: "40px !important",
                                    fontSize: { md: "16px", sm: "14px",xs:"12px" },
                                    padding: { md: "0px 25px", sm: "5px 10px" },
                                }}
                            />
                        ))}
                    </Tabs>
                    <Animation {...getAnimationStyle()} />


                </Toolbar>

                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="options"
                        name="options"
                        value={selectedTab} // Set the value of RadioGroup to selectedTab
                        onChange={handleChange}
                        sx={{ display: { md: "none", sm: "none", xs: "flex" }, flexDirection: 'column' }}
                    >
                        {
                            method.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={item.label} // Set the value to item.label
                                    control={
                                        <Radio
                                            sx={{
                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                '&.Mui-checked': { color: "red" }
                                            }}
                                        />
                                    }
                                    label={item.label}
                                />
                            ))
                        }
                    </RadioGroup>
                </FormControl>

            </div>
            <Grid className="mt-16 md:px-16 px-8" sx={{display:"flex",justifyContent:{md:"flex-start",sm:"flex-start",xs: "space-between"},width:"100%"}}>
                {selectedTab === "Stripe (Credit/Debit Card)" && (
                    <img src={stripeBlue} alt="Stripe (Credit/Debit Card)" style={{ width: "210px" }} />
                )}
                {selectedTab === "Paypal" && (
                    <img src={paypal} alt="Paypal" style={{ width: "210px" }} />
                )}
                {/* {selectedTab === "Credit/Debit Card" && (
                    <>
                        <img src={visa} alt="Visa" style={{ width: "105px", marginRight: "15px" }} />
                        <img src={masterCard} alt="Mastercard" style={{ width: "105px" }} />
                    </>
                )} */}
            </Grid>

            <Grid sx={{ width: "100%", display: "flex", flexDirection: "column" }} className="py-12 md:px-16 px-8">

                {
                        selectedTab==="Paypal"?
                        <>
                            <Grid>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                placeholder="Email"
                                                error={!!errors.email}
                                                autoComplete="off"
                                                required
                                                className="payment-method-input"
                                                sx={{
                                                    border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                    background: "transparent",
                                                    width: { md: "40%", sm: "66%", xs: "100%" },
                                                    "& .MuiOutlinedInput-root": {
                                                        background: "transparent",
                                                        "& fieldset": {
                                                            border: "none",
                                                            background: "transparent",
                                                        },
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        background: "transparent",
                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                    },
                                                }}
                                            />
                                            {errors.email && (
                                                <Typography
                                                    variant="body2"
                                                    color="error"
                                                    sx={{ mt: 1, ml: 1 }}
                                                >
                                                    {errors.email.message}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>
                            <Grid my={6}>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                placeholder="Passsword"
                                                error={!!errors.password}
                                                autoComplete="off"
                                                type={showPassword ? "text" : "password"}
                                                required
                                                className="payment-method-input"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                edge="end"
                                                                style={{ color: "#FFF" }}
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff style={{ color: themeMode === "dark" ? "#FFF" : "#000" }} />
                                                                ) : (
                                                                    <Visibility style={{ color: themeMode === "dark" ? "#FFF" : "#000" }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                    background: "transparent",
                                                    width: { md: "40%", sm: "66%", xs: "100%" },
                                                    "& .MuiOutlinedInput-root": {
                                                        background: "transparent",
                                                        "& fieldset": {
                                                            border: "none",
                                                            background: "transparent",
                                                        },
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        background: "transparent",
                                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                                    },
                                                }}
                                            />
                                            {errors?.password && (
                                                <Typography
                                                    variant="body2"
                                                    color="error"
                                                    sx={{ mt: 1, ml: 1 }}
                                                >
                                                    {errors.password.message}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>
                        </>
                    // :
                    //     selectedTab === "Credit/Debit Card" ?
                    //         <>
                    //             <Grid>
                    //                 <Controller
                    //                     name="cardHolderName"
                    //                     control={control}
                    //                     render={({ field }) => (
                    //                         <>
                    //                             <TextField
                    //                                 {...field}  
                    //                                 label="Card Holder Name"
                    //                                 error={!!errors.cardHolderName}
                    //                                 // helperText={errors.cardHolderName ? errors.cardHolderName.message : ''} 
                    //                                 required
                    //                                 size="small"
                    //                                 autoComplete="off"
                    //                                 className="payment-method-input"
                    //                                 sx={{
                    //                                     border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                     background: "transparent",
                    //                                     width: { md: "30%", sm: "66%", xs: "100%" },
                    //                                     "& .MuiOutlinedInput-root": {
                    //                                         background: "transparent",
                    //                                         "& fieldset": {
                    //                                             border: "none",
                    //                                             background: "transparent",
                    //                                         },
                    //                                     },
                    //                                     "& .MuiInputBase-input": {
                    //                                         background: "transparent",
                    //                                         color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                     },
                    //                                     '& .MuiInputLabel-root': {
                    //                                       color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                       fontSize: '12px',
                    //                                       marginBottom: '15px',
                    //                                       transform: 'translate(0px, -20px) scale(1)',
                    //                                       '&.MuiInputLabel-shrink': {
                    //                                         transform: 'translate(0px, -20px) scale(1)'
                    //                                       }
                    //                                     },
                    //                                 }}
                    //                             />
                    //                         </>
                    //                     )}
                    //                 />
                    //             </Grid>
                    //             <Grid my={6} sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"}}}>
                    //                 <Controller
                    //                     name="cardNumber"
                    //                     control={control}
                    //                     render={({ field }) => (
                    //                         <>
                    //                             <TextField
                    //                                 {...field}
                    //                                 label="Card Number"
                    //                                 size="small"
                    //                                 error={!!errors.cardNumber}
                    //                                 // helperText={errors.cardNumber ? errors.cardNumber.message : ''} 
                    //                                 required
                    //                                 autoComplete="off"
                    //                                 className="payment-method-input"
                    //                                 sx={{
                    //                                     border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                     background: "transparent",
                    //                                     width: { md: "30%", sm: "66%", xs: "100%" },
                    //                                     "& .MuiOutlinedInput-root": {
                    //                                         background: "transparent",
                    //                                         "& fieldset": {
                    //                                             border: "none",
                    //                                             background: "transparent",
                    //                                         },
                    //                                     },
                    //                                     "& .MuiInputBase-input": {
                    //                                         background: "transparent",
                    //                                         color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                     },
                    //                                     '& .MuiInputLabel-root': {
                    //                                       color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                       fontSize: '12px',
                    //                                       marginBottom: '15px',
                    //                                       transform: 'translate(0px, -20px) scale(1)',
                    //                                       '&.MuiInputLabel-shrink': {
                    //                                         transform: 'translate(0px, -20px) scale(1)'
                    //                                       }
                    //                                     },
                    //                                 }}
                    //                             />
                    //                         </>
                    //                     )}
                    //                 />
                    //                 <Grid mx={{md:3,sm:6,xs:0}} sx={{display:"flex",marginY:{md:"0px",sm:"0px",xs:"40px"},justifyContent:"space-between",width:{md:"23%",sm:"50%",xs:"100%"}}}>
                    //                     <Controller
                    //                         name="cardExpiry"
                    //                         control={control}
                    //                         render={({ field }) => (
                    //                             <>
                    //                                 <TextField
                    //                                     {...field}
                    //                                     label="Expiration date"
                    //                                     placeholder="mm/yy"
                    //                                     size="small"
                    //                                     autoComplete="off"
                    //                                     error={!!errors.cardExpiry}
                    //                                     // helperText={errors.cardExpiry ? errors.cardExpiry.message : ''} 
                    //                                     required
                    //                                     className="payment-method-input"         
                    //                                     sx={{
                    //                                         border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                         background: "transparent",
                    //                                         width: { md: "45%", sm: "45%", xs: "45%" },
                    //                                         "& .MuiOutlinedInput-root": {
                    //                                             background: "transparent",
                    //                                             "& fieldset": {
                    //                                                 border: "none",
                    //                                                 background: "transparent",
                    //                                             },
                    //                                         },
                    //                                         "& .MuiInputBase-input": {
                    //                                             background: "transparent",
                    //                                             color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                         },
                    //                                         '& .MuiInputLabel-root': {
                    //                                             color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                             fontSize: '12px',
                    //                                             marginBottom: '15px',
                    //                                             transform: 'translate(0px, -20px) scale(1)',
                    //                                             '&.MuiInputLabel-shrink': {
                    //                                                 transform: 'translate(0px, -20px) scale(1)'
                    //                                             }
                    //                                         },
                    //                                     }}
                    //                                 />
                    //                             </>
                    //                         )}
                    //                     />
                    //                     <Controller
                    //                         name="cvc"
                    //                         control={control}
                    //                         render={({ field }) => (
                    //                             <>
                    //                                 <TextField
                    //                                     {...field}
                    //                                     label="CVC"
                    //                                     placeholder="***"
                    //                                     size="small"
                    //                                     autoComplete="off"
                    //                                     error={!!errors.cvc}
                    //                                     // helperText={errors.cvc ? errors.cvc.message : ''} 
                    //                                     required
                    //                                     className="payment-method-input"
                    //                                     sx={{
                    //                                         border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                         background: "transparent",
                    //                                         width: { md: "45%", sm: "45%", xs: "45%" },
                    //                                         "& .MuiOutlinedInput-root": {
                    //                                             background: "transparent",
                    //                                             "& fieldset": {
                    //                                                 border: "none",
                    //                                                 background: "transparent",
                    //                                             },
                    //                                         },
                    //                                         "& .MuiInputBase-input": {
                    //                                             background: "transparent",
                    //                                             color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                         },
                    //                                         '& .MuiInputLabel-root': {
                    //                                             color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                             fontSize: '12px',
                    //                                             marginBottom: '15px',
                    //                                             transform: 'translate(0px, -20px) scale(1)',
                    //                                             '&.MuiInputLabel-shrink': {
                    //                                                 transform: 'translate(0px, -20px) scale(1)'
                    //                                             }
                    //                                         },
                    //                                     }}
                    //                                 />
                    //                             </>
                    //                         )}
                    //                     />
                    //                 </Grid>
                    //             </Grid>
                    //             <Grid mb={6}>
                    //                 <Typography sx={{fontSize:"20px",fontWeight:"700",color:themeMode==="dark"?"#FFF":"#000"}}>Billing Address</Typography>
                    //             </Grid>
                    //             <Grid sx={{display:"flex"}}>
                    //                 <Controller
                    //                     name="addressLine1"
                    //                     control={control}
                    //                     render={({ field }) => (
                    //                         <>
                    //                             <TextField
                    //                                 {...field}
                    //                                 label="Address Line 1"
                    //                                 error={!!errors.addressLine1}
                    //                                 size="small"
                    //                                 autoComplete="off"
                    //                                 className="payment-method-input"
                    //                                 sx={{
                    //                                     border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                     background: "transparent",
                    //                                     width: { md: "30%", sm: "80%", xs: "100%" },
                    //                                     "& .MuiOutlinedInput-root": {
                    //                                         background: "transparent",
                    //                                         "& fieldset": {
                    //                                             border: "none",
                    //                                             background: "transparent",
                    //                                         },
                    //                                     },
                    //                                     "& .MuiInputBase-input": {
                    //                                         background: "transparent",
                    //                                         color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                     },
                    //                                     '& .MuiInputLabel-root': {
                    //                                         color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                         fontSize: '12px',
                    //                                         marginBottom: '15px',
                    //                                         transform: 'translate(0px, -20px) scale(1)',
                    //                                         '&.MuiInputLabel-shrink': {
                    //                                             transform: 'translate(0px, -20px) scale(1)'
                    //                                         }
                    //                                     },
                    //                                 }}
                    //                             />
                    //                         </>
                    //                     )}
                    //                 />

                    //                 <Controller
                    //                     name="postalCode"
                    //                     control={control}
                    //                     render={({ field }) => (
                    //                         <>
                    //                             <TextField
                    //                                 {...field}
                    //                                 label="Postal Code*"
                    //                                 size="small"
                    //                                 autoComplete="off"
                    //                                 error={!!errors.postalCode}
                    //                                 className="payment-method-input mx-6"
                    //                                 sx={{
                    //                                     border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                     display:{md:"flex",sm:"none",xs:"none"},
                    //                                     background: "transparent",
                    //                                     width: { md: "15%", sm: "20%", xs: "100%" },
                    //                                     "& .MuiOutlinedInput-root": {
                    //                                         background: "transparent",
                    //                                         "& fieldset": {
                    //                                             border: "none",
                    //                                             background: "transparent",
                    //                                         },
                    //                                     },
                    //                                     "& .MuiInputBase-input": {
                    //                                         background: "transparent",
                    //                                         color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                     },
                    //                                     '& .MuiInputLabel-root': {
                    //                                         color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                         fontSize: '12px',
                    //                                         marginBottom: '15px',
                    //                                         transform: 'translate(0px, -20px) scale(1)',
                    //                                         '&.MuiInputLabel-shrink': {
                    //                                             transform: 'translate(0px, -20px) scale(1)'
                    //                                         }
                    //                                     },
                    //                                 }}
                    //                             />
                    //                         </>
                    //                     )}
                    //                 />
                    //             </Grid>
                    //             <Grid my={6} sx={{ display: "flex",flexDirection:{md:"row",sm:"column",xs:"column"} }}>
                    //                 <Controller
                    //                     name="addressLine2"
                    //                     control={control}
                    //                     render={({ field }) => (
                    //                         <>
                    //                             <TextField
                    //                                 {...field}
                    //                                 label="Address Line 2"
                    //                                 size="small"
                    //                                 error={!!errors.addressLine2}
                    //                                 autoComplete="off"
                    //                                 className="payment-method-input"
                    //                                 sx={{
                    //                                     border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                     background: "transparent",
                    //                                     width: { md: "30%", sm: "80%", xs: "100%" },
                    //                                     marginBottom:{md:"0px",sm:"40px",xs:"15px"},
                    //                                     "& .MuiOutlinedInput-root": {
                    //                                         background: "transparent",
                    //                                         "& fieldset": {
                    //                                             border: "none",
                    //                                             background: "transparent",
                    //                                         },
                    //                                     },
                    //                                     "& .MuiInputBase-input": {
                    //                                         background: "transparent",
                    //                                         color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                     },
                    //                                     '& .MuiInputLabel-root': {
                    //                                         color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                         fontSize: '12px',
                    //                                         marginBottom: '15px',
                    //                                         transform: 'translate(0px, -20px) scale(1)',
                    //                                         '&.MuiInputLabel-shrink': {
                    //                                             transform: 'translate(0px, -20px) scale(1)'
                    //                                         }
                    //                                     },
                    //                                 }}
                    //                             />
                    //                         </>
                    //                     )}
                    //                 />
                    //                 <Grid mx={{ md: 3, sm: 0, xs: 0 }} my={{md:0,sm:3,xs:3}} sx={{ display: "flex",flexDirection:{md:"row",sm:"row",xs:"column"}, marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: {md:"35%",sm:"100%",xs:"100%"} }}>
                    //                     <Controller
                    //                         name="city"
                    //                         control={control}
                    //                         render={({ field }) => (
                    //                             <>
                    //                                 <TextField
                    //                                     {...field}
                    //                                     label="City*"
                    //                                     size="small"
                    //                                     error={!!errors.city}
                    //                                     autoComplete="off"
                    //                                     className="payment-method-input"
                    //                                     sx={{
                    //                                         border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                         background: "transparent",
                    //                                         width: { md: "45%", sm: "45%", xs: "100%" },
                    //                                         "& .MuiOutlinedInput-root": {
                    //                                             background: "transparent",
                    //                                             "& fieldset": {
                    //                                                 border: "none",
                    //                                                 background: "transparent",
                    //                                             },
                    //                                         },
                    //                                         "& .MuiInputBase-input": {
                    //                                             background: "transparent",
                    //                                             color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                         },
                    //                                         '& .MuiInputLabel-root': {
                    //                                             color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                             fontSize: '12px',
                    //                                             marginBottom: '15px',
                    //                                             transform: 'translate(0px, -20px) scale(1)',
                    //                                             '&.MuiInputLabel-shrink': {
                    //                                                 transform: 'translate(0px, -20px) scale(1)'
                    //                                             }
                    //                                         },
                    //                                     }}
                    //                                 />
                    //                             </>
                    //                         )}
                    //                     />

                    //                     <Controller
                    //                         name="country"
                    //                         control={control}
                    //                         render={({ field }) => (
                    //                             <>
                    //                                 <TextField
                    //                                     {...field}
                    //                                     label="Country*"
                    //                                     error={!!errors.country}
                    //                                     size="small"
                    //                                     autoComplete="off"
                    //                                     className="payment-method-input"
                    //                                     sx={{
                    //                                         border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                         background: "transparent",
                    //                                         width: { md: "45%", sm: "45%", xs: "100%" },
                    //                                         marginX:{md:3,sm:3,xs:0},
                    //                                         marginY: { md: 0, sm: 0, xs: 4 },
                    //                                         "& .MuiOutlinedInput-root": {
                    //                                             background: "transparent",
                    //                                             "& fieldset": {
                    //                                                 border: "none",
                    //                                                 background: "transparent",
                    //                                             },
                    //                                         },
                    //                                         "& .MuiInputBase-input": {
                    //                                             background: "transparent",
                    //                                             color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                         },
                    //                                         '& .MuiInputLabel-root': {
                    //                                             color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                             fontSize: '12px',
                    //                                             marginBottom: '15px',
                    //                                             transform: 'translate(0px, -20px) scale(1)',
                    //                                             '&.MuiInputLabel-shrink': {
                    //                                                 transform: 'translate(0px, -20px) scale(1)'
                    //                                             }
                    //                                         },
                    //                                     }}
                    //                                 />
                    //                             </>
                    //                         )}
                   
                    //                     />

                    //                     <Controller
                    //                         name="postalCode"
                    //                         control={control}
                    //                         render={({ field }) => (
                    //                             <>
                    //                                 <TextField
                    //                                     {...field}
                    //                                     label="Postal Code"
                    //                                     size="small"
                    //                                     autoComplete="off"
                    //                                     error={!!errors.postalCode}
                    //                                     required
                    //                                     className="payment-method-input"
                    //                                     sx={{
                    //                                         border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                    //                                         display: { md: "none", sm: "flex", xs: "flex" },
                    //                                         background: "transparent",
                    //                                         width: { md: "15%", sm: "20%", xs: "100%" },
                    //                                         "& .MuiOutlinedInput-root": {
                    //                                             background: "transparent",
                    //                                             "& fieldset": {
                    //                                                 border: "none",
                    //                                                 background: "transparent",
                    //                                             },
                    //                                         },
                    //                                         "& .MuiInputBase-input": {
                    //                                             background: "transparent",
                    //                                             color: themeMode === "dark" ? "#FFF" : "#000",
                    //                                         },
                    //                                         '& .MuiInputLabel-root': {
                    //                                             color: themeMode === 'dark' ? '#ffff' : '#000',
                    //                                             fontSize: '12px',
                    //                                             marginBottom: '15px',
                    //                                             transform: 'translate(0px, -20px) scale(1)',
                    //                                             '&.MuiInputLabel-shrink': {
                    //                                                 transform: 'translate(0px, -20px) scale(1)'
                    //                                             }
                    //                                         },
                    //                                     }}
                    //                                 />
                    //                             </>
                    //                         )}
                    //                     />
                    //                 </Grid>
                    //             </Grid>
                    //         </>
                    :
                        selectedTab === "Stripe (Credit/Debit Card)" ?
                            <>
                                <Grid>
                                    <Controller
                                        name="cardHolderName"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Card Holder Name"
                                                    // error={!!errors.cardHolderName}
                                                    // helperText={errors.cardHolderName ? errors.cardHolderName.message : ''} 
                                                    size="small"
                                                    autoComplete="off"
                                                    required
                                                    className="payment-method-input"
                                                    sx={{
                                                        border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "66%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />
                                                {errors.cardHolderName && (
                                                    <FormHelperText error>
                                                        {errors.cardHolderName.message}
                                                    </FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid my={6} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                                    <Controller
                                        name="cardNumber" 
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: { md: "30%", sm: "66%", xs: "100%" },
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                            fontSize: "12px",
                                                            marginBottom: "8px",
                                                        }}
                                                    >
                                                        Card Number*
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                            padding: "8px",
                                                            borderRadius: "4px",
                                                            backgroundColor: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                            height: "42px",
                                                        }}
                                                    >
                                                        {(!stripe || !elements) ? (
                                                            <Skeleton
                                                                variant="rectangular"
                                                                width="100%"
                                                                height="100%"
                                                                sx={{ borderRadius: "4px" }}
                                                            />
                                                        ) : (
                                                            <CardNumberElement
                                                                {...field}
                                                                options={{
                                                                    showIcon: true,
                                                                    style: {
                                                                        base: {
                                                                            iconColor: themeMode === "dark" ? "#FFF" : "#000",
                                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                                            fontSize: "16px",
                                                                            "::placeholder": {
                                                                            color: themeMode === "dark" ? "#AAA" : "#888",
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                                onChange={(event) => field.onChange(event.complete ? event : null)}
                                                            />
                                                        )}
                                                    </Box>
                                                    {errors.cardNumber && (
                                                        <FormHelperText error>
                                                            {errors.cardNumber.message}
                                                        </FormHelperText>
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                    />
                                    <Grid mx={{ md: 3, sm: 6, xs: 0 }} sx={{ display: "flex", gap: { md: 3, sm: 2, xs: 1 }, marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: { md: "23%", sm: "50%", xs: "100%" } }}>
                                        <Controller
                                            name="cardExpiry"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: { md: "45%", sm: "45%", xs: "100%" },
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                                fontSize: "12px",
                                                                marginBottom: "8px",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            Expiration date* 
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                padding: "8px",
                                                                borderRadius: "4px",
                                                                backgroundColor: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                                height: "42px",
                                                            }}
                                                        >
                                                            {(!stripe || !elements) ? (
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    width="100%"
                                                                    height="100%"
                                                                    sx={{ borderRadius: "4px" }}
                                                                />
                                                            ) : (
                                                                <CardExpiryElement
                                                                    {...field}
                                                                    options={{
                                                                        style: {
                                                                            base: {
                                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                                                fontSize: "16px",
                                                                                "::placeholder": {
                                                                                    color: themeMode === "dark" ? "#AAA" : "#888",
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                    onChange={(event) => field.onChange(event.complete ? event : null)}
                                                                />
                                                            )}
                                                        </Box>
                                                        {errors.cardExpiry && (
                                                            <FormHelperText error>
                                                                {errors.cardExpiry.message}
                                                            </FormHelperText>
                                                        )}
                                                    </Box>
                                                </>
                                            )}
                                        />
                                        <Controller
                                            name="cvc"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: { md: "45%", sm: "45%", xs: "100%" },
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                                fontSize: "12px",
                                                                marginBottom: "8px",
                                                            }}
                                                        >
                                                            CVC*
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                border: themeMode === "dark" ? "1px solid #FFFFFF" : "1px solid #000",
                                                                padding: "8px",
                                                                borderRadius: "4px",
                                                                backgroundColor: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                                height: "42px",
                                                            }}
                                                        >
                                                            {(!stripe || !elements) ? (
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    width="100%"
                                                                    height="100%"
                                                                    sx={{ borderRadius: "4px" }}
                                                                />
                                                            ) : (
                                                                <CardCvcElement
                                                                    {...field}
                                                                    options={{
                                                                        style: {
                                                                            base: {
                                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                                                fontSize: "16px",
                                                                                "::placeholder": {
                                                                                    color: themeMode === "dark" ? "#AAA" : "#888",
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                    onChange={(event) => field.onChange(event.complete ? event : null)}
                                                                />
                                                            )}
                                                        </Box>
                                                        {errors.cvc && (
                                                            <FormHelperText error>
                                                                {errors.cvc.message}
                                                            </FormHelperText>
                                                        )}
                                                    </Box>
                                                </>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid mb={6}>
                                    <Typography sx={{ fontSize: "20px", fontWeight: "700",color:themeMode==="dark"?"#FFF":"#000" }}>Billing Address (Optional)</Typography>
                                </Grid>
                                <Grid sx={{ display: "flex" }}>
                                    <Controller
                                        name="addressLine1"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Address Line 1"
                                                    error={!!errors.addressLine1}
                                                    size="small"
                                                    autoComplete="off"
                                                    className="payment-method-input"
                                                    sx={{
                                                        border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "80%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        name="postalCode"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Postal Code"
                                                    size="small"
                                                    autoComplete="off"
                                                    error={!!errors.postalCode}
                                                    className="payment-method-input mx-6"
                                                    sx={{
                                                        border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                        display: { md: "flex", sm: "none", xs: "none" },
                                                        background: "transparent",
                                                        width: { md: "15%", sm: "20%", xs: "100%" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid my={6} sx={{ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" } }}>
                                    <Controller
                                        name="addressLine2"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    label="Address Line 2"
                                                    size="small"
                                                    error={!!errors.addressLine2}
                                                    autoComplete="off"
                                                    className="payment-method-input"
                                                    sx={{
                                                        border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                        background: "transparent",
                                                        width: { md: "30%", sm: "80%", xs: "100%" },
                                                        marginBottom: { md: "0px", sm: "40px", xs: "15px" },
                                                        "& .MuiOutlinedInput-root": {
                                                            background: "transparent",
                                                            "& fieldset": {
                                                                border: "none",
                                                                background: "transparent",
                                                            },
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            background: "transparent",
                                                            color: themeMode === "dark" ? "#FFF" : "#000",
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: themeMode === 'dark' ? '#ffff' : '#000',
                                                            fontSize: '12px',
                                                            marginBottom: '15px',
                                                            transform: 'translate(0px, -20px) scale(1)',
                                                            '&.MuiInputLabel-shrink': {
                                                                transform: 'translate(0px, -20px) scale(1)'
                                                            }
                                                        },
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                    <Grid mx={{ md: 3, sm: 0, xs: 0 }} my={{ md: 0, sm: 3, xs: 3 }} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }, marginY: { md: "0px", sm: "0px", xs: "40px" }, justifyContent: "space-between", width: { md: "35%", sm: "100%", xs: "100%" } }}>
                                        <Controller
                                            name="city"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="City"
                                                        size="small"
                                                        error={!!errors.city}
                                                        autoComplete="off"
                                                        className="payment-method-input"
                                                        sx={{
                                                            border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                            background: "transparent",
                                                            width: { md: "45%", sm: "45%", xs: "100%" },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                        <Controller
                                            name="country"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        label="Country"
                                                        error={!!errors.country}
                                                        size="small"
                                                        autoComplete="off"
                                                        className="payment-method-input"
                                                        sx={{
                                                            border: themeMode==="dark"?"1px solid #FFFFFF":"1px solid #000",
                                                            background: "transparent",
                                                            width: { md: "45%", sm: "45%", xs: "100%" },
                                                            marginX: { md: 3, sm: 3, xs: 0 },
                                                            marginY: { md: 0, sm: 0, xs: 4 },
                                                            "& .MuiOutlinedInput-root": {
                                                                background: "transparent",
                                                                "& fieldset": {
                                                                    border: "none",
                                                                    background: "transparent",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                background: "transparent",
                                                                color: themeMode === "dark" ? "#FFF" : "#000",
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: themeMode === 'dark' ? '#ffff' : '#000',
                                                                fontSize: '12px',
                                                                marginBottom: '15px',
                                                                transform: 'translate(0px, -20px) scale(1)',
                                                                '&.MuiInputLabel-shrink': {
                                                                    transform: 'translate(0px, -20px) scale(1)'
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                    :
                    ""
                }
                    
                
                <Grid className={themeMode==="dark"?"text-light":"text-dark"}>
                <Controller
                    name="default_method"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                            sx={{ color: themeMode === "dark" ? "#FFF" : "#000" }}
                            control={
                                <Checkbox
                                    inputRef={ref}
                                    size="large"
                                    checked={value || ''} 
                                    onChange={(event) => {
                                        onChange(event.target.checked); 
                                    }}
                                    sx={{
                                        color: themeMode === "dark" ? "#FFF" : "#000",
                                        "&.Mui-checked": {
                                            color: themeMode === "dark" ? "#FFF" : "#000"
                                        },
                                    }}
                                />
                            }
                            label="Set as primary payment method"
                        />
                    )}
                />
                </Grid>
                <div className="pt-10">
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            backgroundColor: "#E50000",
                            color: "white",
                            borderRadius: "30px",
                            border:"1px solid #FFF"
                        }}
                        disabled={!stripe || !elements || loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        <span className="sign-button">{loading ? "Loading..." : "Pay and Add payment method"}</span>
                    </Button>
                    <Typography className={`pt-6 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
                        Payment information is encrypted and secure. Learn more about our{" "}
                        <Button disableRipple onClick={() => (navigate("/privacy-policy"))} 
                        sx={{padding:"0px",minWidth:"0px",textTransform:"none", fontSize: "18px", fontStyle: "italic", fontWeight: "700", color: themeMode === "dark" ? "#FFF" : "#000",textDecoration:"none","&:hover":{background:"transparent"} }}>
                            Privacy Policy.
                        </Button>
                    </Typography>

                </div>
            </Grid>
        </div>
    )
}
export default PayNowPaymentMethod;