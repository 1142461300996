import { useState, React, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import OnboardingNav from "./OnboardingNav";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChosePaymentPlan, processPayment } from "../store/onboardingSlice";
import { error, success } from "../store/alertSlice";
import { generatePdfUsingHtmltoImage } from "../../utils/globalFunctions";
const paypal =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/paypal.png";
const stripe =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/stripe.png";
const bkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeFinal.gif";
const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

const PlanSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chosenPaymentPlan = useSelector(
    (state) => state.onboarding.chosenPaymentPlan
  );

  const [payMethod, setPayMethod] = useState("stripe");
  const [status, setStatus] = useState("pay"); //pay or paid

  const handlePaymentChange = (event) => {
    setPayMethod(event.target.value);
  };

  useEffect(() => {
    dispatch(getChosePaymentPlan());
  }, []);

  const [animationClass, setAnimationClass] = useState("page-enter");

  useEffect(() => {
    setAnimationClass("page-enter");
    return () => {};
  }, []);

  const handlePageLeave = () => {
    generatePdfUsingHtmltoImage(
      document.getElementById("plan_subscription"),
      "plan_subscription",
      dispatch
    );
    setAnimationClass("page-exit");
    setTimeout(() => {
      navigate("/connect-linkedIn");
    }, 500);
  };

  return (
    <Grid
      className="welcome"
      id="plan_subscription"
      sx={{
        backgroundImage:
          "linear-gradient(to right, #b03302 0%, #FF3001 20%, #FF3001 80%, #b03302 100%)",
        height: "100vh",
        //backgroundSize: "auto !important",
      }}
    >
      <OnboardingNav selected={4} />
      {/* <div className="mb-10"></div> */}
      <Grid
        className={animationClass}
        mt={{ md: 16, sm: 18, xs: 18 }}
        mb={{ md: "", sm: 8, xs: 10 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { md: "flex-start", sm: "center", xs: "center" },
          justifyContent: "center",
          width: { md: "53%", sm: "53%", xs: "80%" },
          //transform: { md: "scale(1)", sm: "scale(0.8)", xs: "scale(1)" },
        }}
      >
        <img src="/logo.png" alt="" />
        <Typography
          sx={{
            fontSize: { md: "40px", sm: "35px", xs: "26px" },
            fontWeight: "700",
          }}
        >
          Plan Subscription
        </Typography>
        {!chosenPaymentPlan?.already_paid_by_organization ? (
          <Typography
            sx={{
              fontSize: { md: "18px", sm: "16px", xs: "12px" },
              fontWeight: "400",
            }}
          >
            As per your plan choice
            <span
              style={{
                fontSize: { md: "22px", sm: "20px", xs: "16px" },
                fontWeight: "700",
                margin: "0px 5px",
              }}
            >
              ({chosenPaymentPlan?.name})
            </span>
            , you will pay
            <span
              style={{
                fontSize: { md: "22px", sm: "20px", xs: "16px" },
                fontWeight: "700",
                margin: "0px 5px",
              }}
            >
              ${chosenPaymentPlan?.price}/{chosenPaymentPlan?.tier}
            </span>
            .
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: { md: "18px", sm: "16px", xs: "12px" },
              fontWeight: "400",
            }}
          >
            You organization has already paid for your plan
            <span
              style={{
                fontSize: { md: "22px", sm: "20px", xs: "16px" },
                fontWeight: "700",
                margin: "0px 5px",
              }}
            >
              ({chosenPaymentPlan?.name})
            </span>
          </Typography>
        )}

        <Typography
          sx={{
            marginY: { md: "25px", sm: "15px", xs: "10px" },
            fontSize: { md: "18px", sm: "18px", xs: "13px" },
            fontWeight: "700",
            marginBottom: { md: "", sm: "0px" },
            width: "100%",
            textAlign: "left",
          }}
        >
          Benefits:
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "16px", sm: "16px", xs: "13px" },
            fontWeight: "400",
            margin: {
              md: "25px 0px 25px 25px",
              sm: "25px 0px 15px 25px",
              xs: "10px 0px 10px 15px",
            },
          }}
        >
          <ul>
            {chosenPaymentPlan?.benefits?.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </Typography>

        <Grid
          my={{ md: 2, sm: 0 }}
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: !chosenPaymentPlan?.already_paid_by_organization
              ? "flex"
              : "none",
            alignItems: "center",
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={payMethod}
              onChange={handlePaymentChange}
            >
              <Grid
                item
                md={5}
                sm={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                <FormControlLabel
                  value="paypal"
                  control={
                    <Radio
                      disabled
                      sx={{
                        color: "#ffff",
                        "&.Mui-checked": {
                          color: "#FFFF",
                        },
                      }}
                    />
                  }
                  label={
                    <img
                      src={"/paypal.png"}
                      alt="PayPal"
                      style={{ height: "auto", width: "100%" }}
                    />
                  }
                />
                <FormControlLabel
                  value="stripe"
                  control={
                    <Radio
                      sx={{
                        color: "#ffff",
                        "&.Mui-checked": {
                          color: "#FFF",
                        },
                      }}
                    />
                  }
                  label={
                    <img
                      src={"/stripe.png"}
                      alt="Stripe"
                      style={{ height: "auto", width: "100%" }}
                    />
                  }
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        <div className="flex space-x-4">
          <Button
            onClick={() => {
              if (!chosenPaymentPlan?.already_paid_by_organization) {
                generatePdfUsingHtmltoImage(
                  document.getElementById("plan_subscription"),
                  "plan_subscription",
                  dispatch
                );
                navigate("/payment-form");
              } else {
                generatePdfUsingHtmltoImage(
                  document.getElementById("plan_subscription"),
                  "plan_subscription",
                  dispatch
                );
                navigate("/connect-linkedIn");
              }
            }}
            type="submit"
            variant="contained"
            sx={{
              fontWeight: "700",
              marginY: "20px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "capitalize",
              padding: "8px 20px",
              border: "1px solid transparent",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            {!chosenPaymentPlan?.already_paid_by_organization
              ? "Pay Now"
              : "Next"}
          </Button>
          <Button
            onClick={() => {
              handlePageLeave();
            }}
            type="submit"
            variant="contained"
            sx={{
              fontWeight: "700",
              marginY: "20px",
              backgroundColor: "transparent",
              borderRadius: "30px",
              color: "white",
              textTransform: "capitalize",
              padding: "8px 20px",
              border: "1px solid white",
              "&:hover": {
                background: "white",
                color: "#B50000",
                border: "1px solid #FFF",
              },
            }}
          >
            Skip Now & Pay Later
          </Button>{" "}
        </div>
      </Grid>
    </Grid>
  );
};
export default PlanSubscription;
