import { MissionCard } from "./MissionCard";
const glowBg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/redGlowBalls.png"

export const Mission = () => {
  return (
    <div className="p-14 mt-10 py-24 text-white md:px-16 px-16 w-full flex justify-center"
      id="mission"
      style={{
        backgroundImage: `url(${glowBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "80%",
        backgroundPosition: "45% 10%"
      }}
    >

      <div className="md:flex justify-between w-full md:space-x-10 md:w-3/4 space-y-16 md:space-y-0 sm:w-2/4 xs:space-x-6">
        <MissionCard
          imageSrc={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/rocket2.gif"}
          title={"Mission"}
          content={
            "At FacoonLabs, our mission is to empower professionals and businesses to build meaningful connections effortlessly. By leveraging LinkedIn's API and smart campaign automation, we help you reach your network with personalized messages, fostering authentic engagement and long-term growth."
          }
        />
        <MissionCard
          imageSrc={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/aim.gif"}
          title={"Vision"}
          content={
            "To revolutionize professional networking by creating a seamless platform where meaningful connections drive innovation, collaboration, and success. We envision a world where technology bridges the gap between professionals, fostering authentic relationships and opportunities on a global scale."
          }
        />
      </div>
    </div>
  );
};
