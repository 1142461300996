import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import "./Dashboard.css";
import { Login } from "./app/auth/Login";
import { Register } from "./app/auth/Register";
import { AboutUs } from "./app/website/about-us/AboutUs";
import { ContactUs } from "./app/website/contact-us/ContactUs";
import { HomePage } from "./app/website/homepage/HomePage";
import { Route, Routes } from "react-router-dom";
import { Pricing } from "./app/website/pricing/Pricing";
import Dashboard from "./app/dashboard/Dashboard";
import { HelpCenter } from "./app/website/help/Help";
import ProtectedRoute from "./utils/protectedRoutes";
import NonProtectedRoute from "./utils/nonProtectedRoutes";
import axios from "axios";
import Welcome from "./app/onboarding/Welcome";
import SetPassword from "./app/onboarding/SetPassword";
import PlanSubscription from "./app/onboarding/PlanSubscription";
import CompleteOnboarding from "./app/onboarding/Complete";
import { getUserFromLocalStorage } from "./app/services/localStorageService";
import { getUserProfile } from "./app/store/userProfileSlice";
import ConnectLinkedIn from "./app/onboarding/ConnectLinkedIn";
import ConfirmDetails from "./app/onboarding/ConfirmDetails";
import StripePaymentForm from "./app/onboarding/StripePaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LimitationsBestPractice from "./app/website/limitation/LimitationsBestPractice";
import { getLinkedInConnectedStatus } from "./app/store/settingsSlice";
import General from "./app/website/limitation/LimitationsBestPractice";
import PrivacyPolicy from "./app/website/privacyPolicy/PrivacyPage";
import TermsAndCondition from "./app/website/terms&condition/Terms&Condition";
import { requestForToken, onMessageListener } from "./firebase";
import { getTokens } from "./app/store/loginSlice";
import { getChat, setOpenChatBox } from "./app/store/messagesSlice";
import { banner } from "./app/store/alertSlice";
import { ResetPassword } from "./app/website/reset-password/ResetPassword";
import { ForgotPassword } from "./app/auth/ForgotPassword";
import {
  getNotifications,
  setNotifications,
} from "./app/store/notificationSlice";
import Impersonate from "./app/auth/Impersonate";
import Calculator from "./app/calculator/Calculator";
import AIMagicLoad  from "./app/calculator/Loading";

axios.defaults.headers["client"] = localStorage.getItem("client");
axios.defaults.headers["uid"] = localStorage.getItem("uid");
axios.defaults.headers["access-token"] = localStorage.getItem("access-token");

let stripePromise;
const stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (stripe_public_key) {
  try {
    stripePromise = loadStripe(stripe_public_key);
    console.info("stripe laoded");
  } catch (error) {
    console.error("Failed to load Stripe:", error);
  }
}

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfile.userProfile);

  useEffect(() => {
    const user = getUserFromLocalStorage();
    if (user && user["access-token"] && user["client"] && user["uid"]) {
      dispatch(getUserProfile());
      dispatch(getLinkedInConnectedStatus());
      requestForToken(dispatch);
      if(user.profile?.operational_status_type === "inactive_due_to_payment" && user.profile?.organization_role_type === "admin"){
        dispatch(banner({
          bannerMessage: "Your account is inactive due to payment issues. Please click it to update your payment details to reactivate your account. Click it now!",
          bannerUrl: "/dashboard/payments/manage-payment-methods/pay-now-payment-method"
        }));
      }
      else if(user.profile?.operational_status_type === "inactive_due_to_payment" && user.profile?.organization_role_type !== "admin"){
        dispatch(banner({
          bannerMessage: "Your account is inactive due to payment issues. Please contact your admin to update payment details to reactivate account.",
          bannerUrl: null,
        }));
      }
    }
  }, []);


  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        console.log("Message received from service worker:", event.data);
        dispatch(setNotifications([]));

        dispatch(getNotifications());

        const { notificationable_uuid } = event.data;

        if (notificationable_uuid) {
          dispatch(getChat(notificationable_uuid)).then(() => {
            dispatch(setOpenChatBox(true));
          });
        }
      });
    }
  }, [dispatch]);

  onMessageListener()
    .then((payload) => {
      console.log("Received foreground notification:", payload);
      dispatch(setNotifications([]));
      dispatch(getNotifications());
    })
    .catch((err) => console.error("Failed to receive notification:", err));

  return (
    <div>
      <Routes>
        <Route element={<NonProtectedRoute user={user} />}>
          <Route path="/login" index element={<Login />} />
          <Route path="/forgot-password" index element={<ForgotPassword />} />
        </Route>
        <Route path="/register" index element={<Register />} />
        <Route path="/" index element={<HomePage />} />
        <Route path="/about" index element={<AboutUs />} />
        <Route path="/contact" index element={<ContactUs />} />
        <Route path="/pricing" index element={<Pricing />} />
        <Route path="/faqs" index element={<HelpCenter />} />
        <Route path="/privacy-policy" index element={<PrivacyPolicy />} />
        <Route path="/terms-condition" index element={<TermsAndCondition />} />
        <Route
          path="/limitations-and-best-practices"
          index
          element={<General />}
        />
        <Route path="/ai-calculator" index element={<Calculator />} />
        <Route path="/ai-results" index element={<AIMagicLoad />} />
        <Route path="/impersonate" index element={<Impersonate />} />
        <Route path="/welcome" index element={<Welcome />} />
        <Route path="/confirm-details" index element={<ConfirmDetails />} />
        <Route path="/set-password" index element={<SetPassword />} />
        <Route path="/plan-subscription" index element={<PlanSubscription />} />

        <Route
          path="/payment-form"
          index
          element={
            <Elements stripe={stripePromise}>
              <StripePaymentForm />
            </Elements>
          }
        />

        <Route path="/onboarding-complete" index element={<CompleteOnboarding />}/>
        <Route path="/connect-linkedIn" index element={<ConnectLinkedIn />} />
        <Route path="/reset-password" index element={<ResetPassword />} />

        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/dashboard/*" index element={<Dashboard />} />
        </Route>
        
      </Routes>
    </div>
  );
}

export default App;
export { stripePromise };
