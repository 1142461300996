import { Outlet, Navigate, Route, Routes } from "react-router-dom";
import { Campaigns } from "./campaign/Campaigns";
import { Connections } from "./connections/Connections";
import { Meetings } from "./meetings/Meetings";
import { Settings } from "./settings/Settings";
import { Messages } from "./messages/messages";
import { OverviewTab } from "./overviewTab/OverviewTab";
import { CampaignDetail } from "./campaign/campaign-detail/CampaignDetail";
import { Payment } from "./payments/Payment";
import WelcomeDashboard from "./WelcomeDashboard";
import HelpCenter from "./helpCenter/HelpCenter";
import PaymentMethods from "./payments/PaymentMethod";
import AddPaymentMethod from "./payments/AddPaymentMethod";
import PayNowPaymentMethod from "./payments/PayNowPaymentMethod";
import MessageFlow from "./campaign/campaign-detail/automated-messaging/MessageFlow";
import { ResetPassword } from "../website/reset-password/ResetPassword";
import ConnectionMessageRequest from "./campaign/campaign-detail/automated-messaging/ConnectionMessageRequest";
import NurtureMessageFlow from "./campaign/campaign-detail/automated-messaging/NurtureMessageFlow";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from './../../App';

const Preview = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Outlet />
      <Routes>
        <Route path="overview" index element={<OverviewTab />} />
        <Route path="campaigns" index element={<Campaigns />} />
        <Route path="messages" index element={<Messages />} />
        <Route path="campaigns/:name/:id" element={<CampaignDetail />} />
        <Route
          path="campaigns/:name/:id/message-flow"
          element={<MessageFlow />}
        />
        <Route
          path="campaigns/:name/:id/message-flow-nurture"
          element={<NurtureMessageFlow />}
        />

        <Route path="connections" index element={<Connections />} />
        <Route path="meetings" index element={<Meetings />} />
        <Route path="payments" index element={<Payment />} />
        <Route path="settings" index element={<Settings />} />
        <Route path="campaigns/:name/:id/connection-request-message" element={<ConnectionMessageRequest />} />

        <Route path="help-center" index element={<HelpCenter />} />
        <Route path="payments/manage-payment-methods" index element={<PaymentMethods />} />
        <Route path="payments/manage-payment-methods/add-payment-method" index 
          element={
            <Elements stripe={stripePromise}>
              <AddPaymentMethod />
            </Elements>
          } 
        />
        <Route path="payments/manage-payment-methods/pay-now-payment-method" index 
          element={
            <Elements stripe={stripePromise}>
              <PayNowPaymentMethod />
            </Elements>
          } 
        />
        {/* <Route path="reset-password" index element={<ResetPassword />} /> */}

        {/* <Route path="welcome-dashboard" index element={<WelcomeDashboard />} /> */}

        <Route path="*" element={<Navigate to="overview" />} />
      </Routes>
    </div>
  );
};
export default Preview;
